import { ReactNode, useEffect, useRef, useState } from 'react';

import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { Chart as ChartJS, ChartData, ChartOptions } from 'chart.js';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Bar } from 'react-chartjs-2';
import { isMobileOnly } from 'react-device-detect';
import { useSelector } from 'react-redux';

import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';

interface props {
  data: ChartData<'bar', number[], unknown>;
  options?: ChartOptions;
  title?: ReactNode;
  showTitle?: boolean;
  gridData?: string[][];
  columnName?: string[];
  displayDT?: boolean;
  stacked?: boolean;
  blueColor: boolean;
  minHeight?: string;
}

const BarChart: React.FC<props> = ({
  data,
  gridData,
  title,
  showTitle,
  columnName,
  displayDT,
  stacked,
  blueColor,
  minHeight,
  options,
}) => {
  const chartRef = useRef<ChartJS<'bar'>>(null);
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const dataFactoryCTX = useDataFactory();
  const [chartData, setChartData] = useState<ChartData<'bar'>>({
    datasets: [],
  });
  useEffect(() => {
    const chart = chartRef.current;

    if (!chart) {
      return;
    }

    const chartData = {
      ...data,

      datasets: data.datasets.map((dataset) => ({
        ...dataset,
        label: dataset.label ?? 'Occupancy',
        data: dataset.data,
        borderRadius: 2,
      })),
    };
    if (blueColor) {
      chartData.datasets.map(
        (e) => (e.backgroundColor = [darkMode ? colors.colorPrimaryLight : colors.colorPrimaryMain]),
      );
    }

    setChartData(chartData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <div
      style={{
        padding: isMobileOnly ? '0' : '20px',
        width: '100%',
      }}
    >
      {showTitle && (
        <>
          <Typography
            component="h1"
            variant="h4"
            sx={{
              padding: 2,
              fontSize: 17,
              fontWeight: 'bolder',
              textTransform: 'uppercase',
            }}
          >
            {title}
          </Typography>

          <Divider variant="middle" />
          <br />
        </>
      )}
      {
        <div style={{ width: '100%' }}>
          <Bar
            ref={chartRef}
            data={chartData}
            style={{ height: minHeight ? minHeight : '200px' }}
            options={{
              ...options,
              plugins: {
                tooltip: {
                  mode: 'index',
                  intersect: false,
                },
              },
              responsive: true,
              maintainAspectRatio: false,
              layout: { padding: { left: displayDT ? 100 : 0, right: 0 } },
              scales: {
                y: { beginAtZero: true, stacked: stacked, border: { color: '#333', width: 1 } },
                x: {
                  display: displayDT ? false : true,
                  stacked: stacked,
                  type: 'category',
                  stackWeight: 1,
                  border: { color: '#333', width: 1 },
                },
              },
            }}
          />
        </div>
      }
      {displayDT && (
        <DataTable
          value={gridData?.filter((e) => {
            if (e[0] === 'Chambre distribuées' && dataFactoryCTX.PMSSettings?.ManageEstock === true) {
              return e;
            } else if (e[0] === 'Day use' && dataFactoryCTX.PMSSettings?.DayUseEnabled === true) {
              return e;
            } else {
              return e;
            }
          })}
          rowHover
          style={{ padding: 0 }}
          stripedRows
          className="normalDT previsionDT"
        >
          <Column field={'0'} header="" style={{ minWidth: '140px', maxWidth: '140px', width: '140px' }}></Column>

          {columnName?.map((i, index) => {
            return (
              <Column
                key={index}
                body={(e, t) => {
                  if (e[0] === 'Chambre distribuées') {
                    if (
                      e[t.field.split('_')[1]].toString() === '' ||
                      e[t.field.split('_')[1]].toString() === undefined ||
                      e[t.field.split('_')[1]].toString() === null
                    ) {
                      return <i className="fa-solid fa-lock" style={{ color: '#f20202' }}></i>;
                    } else {
                      return e[t.field.split('_')[1]].toString();
                    }
                  } else {
                    return e[t.field.split('_')[1]].toString();
                  }
                }}
                header={i}
                headerStyle={{ padding: 0 }}
              ></Column>
            );
          })}
        </DataTable>
      )}
    </div>
  );
};

export default BarChart;
