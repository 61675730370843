import React, { useCallback, useEffect, useRef, useState } from 'react';

import PlanningFilters from '@app/components/Common/filter/PlanningFilters';
import LightBookingSummary from '@app/components/Common/planning/LightBookingSummary';
import { useBookings } from '@app/modules/Booking/BookingsContext';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { usePlanning } from '@app/modules/planning/PlanningContext';
import { useUser } from '@app/modules/user/UserContext';
import { useWindow } from '@app/modules/WindowContext';
import { RootState } from '@app/store/store';
import { Room } from '@app/types/Hotel/room';
import { PlanningColor, PlanningLine } from '@app/types/types';
import { getDeltaInDays } from '@app/utils/dates';
import { useMediaQuery } from '@app/utils/useMediaQuery';
import {
  Eventcalendar,
  localeAr,
  localeDe,
  localeEn,
  localeEs,
  localeFr,
  localeZh,
  MbscCalendarEventData,
  MbscEventClickEvent,
} from '@mobiscroll/react';
import { MbscCalendarDayData } from '@mobiscroll/react/dist/src/core/shared/calendar-view/calendar-view.types.public';
import { debounce } from 'lodash';
import moment from 'moment';
import { Calendar, CalendarDateTemplateEvent } from 'primereact/calendar';
import { isMobile, isMobileOnly, isTablet, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import CustomDay from '../../components/Common/planning/CustomDay';
import CustomResource from '../../components/Common/planning/CustomResource';
import CustomResourceBreakingType from '../../components/Common/planning/CustomResourceBreakingType';
import PlanningFooter from '../../components/Common/planning/planningFooter';
import Point from '../../components/Common/planning/point';
import Reservation from '../../components/Common/planning/Reservation';
import ResourceFooter from '../../components/Common/planning/ResourceFooter';

import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { useSubForm } from '@app/modules/SubFormContext';
import { storage } from '@app/store/localstorage/localStorage';

type RoomAccumulator = { [key: string]: Room[] };

const Planning: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const planningCTX = usePlanning();
  const dataFactoryCTX = useDataFactory();
  const planning = planningCTX.Planning;
  const subFormCTX = useSubForm();

  const planningDetail = planningCTX.PlanningDetail;
  const userCTX = useUser();
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const zoneEvents = planningCTX.ZoneEvents;
  const filtersCTX = useFilters();
  const rooms = dataFactoryCTX.DataDictionnary.Rooms;
  const PlanningDate = userCTX.authenticationInfos.selectedHotel?.CurrentPlanningDate;
  const [myPlanningColor, setMyPlanningColor] = useState<PlanningColor[]>([]);
  const bookingCTX = useBookings();
  const [myResources, setMyResources] = useState<Room[]>([]);
  const [event, setEvent] = useState<PlanningLine[]>([]);
  const [firstCallDone, setFirstCallDone] = useState(false);
  const windowCTX = useWindow();
  const [showCalendar, setShowCalendar] = useState(false);
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const [t] = useTranslation();

  const isPortrait = useMediaQuery('(orientation: portrait)');
  const isLandscape = useMediaQuery('(orientation: landscape)');
  const isNarrowScreen = useMediaQuery('(max-width: 900px)');

  const [isConditionMet, setIsConditionMet] = useState(false);

  useEffect(() => {
    setIsConditionMet(isPortrait && isNarrowScreen);
  }, [isPortrait, isNarrowScreen, isLandscape]);
  // Fonction pour ouvrir le calendrier

  useEffect(() => {
    return () => {
      if (windowCTX) {
        windowCTX.hideWindow(10000);
      }
      if (subFormCTX) {
        subFormCTX.hideSubForm(666);
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          availabiltyFilters: {
            DateStart: null,
            DateEnd: null,
            hasBeenModified: false,
            needReload: false,
            ColoredDates: [],
          },
        }));
        planningCTX.setFreeAvailabilities(null);
        planningCTX.setRates(null);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const res = myResources.map((e) => {
      return { ...e, collapsed: !filtersCTX.filters.planningFilters.IsCollapse };
    });
    setMyResources(res);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX]);

  useEffect(() => {
    if (userCTX.authenticationInfos.wsToTreat?.Type === 'UpdateEvent' && planning && planningCTX) {
      const message: any = userCTX.authenticationInfos.wsToTreat;
      planningCTX.setPlanning(
        planning.map((e) => {
          if (e.Id === message.Id) {
            return {
              ...e,
              ...message,
              RoomNumber: message.resource,
              Start: moment(message.start).format('YYYYMMDD'),
              End: moment(message.end).format('YYYYMMDD'),
              IdRoom: message.resource,
            };
          }
          return e;
        }),
      );
      userCTX.setUser((prevState) => ({
        ...prevState,
        wsToTreat: null,
      }));
    }
  }, [planning, planningCTX, userCTX, userCTX.authenticationInfos.wsToTreat]);

  useEffect(() => {
    if (filtersCTX.filters.planningFilters.needReload === true) {
      planningCTX.getPlanning(filtersCTX.filters.planningFilters);

      planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);
      planningCTX.getZoneEvents(filtersCTX.filters.planningFilters);

      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        planningFilters: {
          ...prevState.planningFilters,
          needReload: false,
        },
      }));
    }
  }, [filtersCTX, filtersCTX.filters.planningFilters.needReload, planningCTX]);
  useEffect(() => {
    if (
      PlanningDate &&
      firstCallDone === false &&
      rooms &&
      selectedHotel &&
      filtersCTX.filters.planningFilters.IdHotel !== 0
    ) {
      planningCTX.getPlanning(filtersCTX.filters.planningFilters);
      planningCTX.getZoneEvents(filtersCTX.filters.planningFilters);
      planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);

      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        planningFilters: {
          ...prevState.planningFilters,
          TypeToDisplay: rooms
            .map((e) => e.RoomType ?? t('labels.sr'))
            .filter((value, index, self) => self.indexOf(value) === index),
          CurrentSort:
            storage.getParam('CurrentSort') !== null
              ? parseInt(storage.getParam('CurrentSort') as string)
              : dataFactoryCTX.PMSSettings?.PlanningDefautTrieParType === true
                ? 1
                : 0,
          ForceOneDay:
            storage.getParam('CurrentSort') !== null
              ? parseInt(storage.getParam('CurrentSort') as string) === 3
                ? true
                : false
              : false,
        },
      }));

      setFirstCallDone(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PlanningDate, firstCallDone, rooms, selectedHotel, filtersCTX]);

  const countNumberOfEventForResource = useCallback(
    (res: string, index: number) => {
      const currentRes = myResources.find((e) => e.Id === res);
      if (planningDetail) {
        return planningDetail.Data.find((e) => e.Name === currentRes?.RoomNumber)?.Data.find(
          (t) => t.KeyIterator === index,
        )?.Value;
      }
    },
    [myResources, planningDetail],
  );

  // Creation des ressources (chambres et types)
  useEffect(() => {
    if (rooms && PlanningDate && filtersCTX.filters.planningFilters) {
      switch (filtersCTX.filters.planningFilters?.CurrentSort) {
        case 0:
          setMyResources(
            rooms

              .map((e) => {
                return { ...e, key: e.Id, id: e.Id };
              })
              .sort((a, b) => a.PositionRoom - b.PositionRoom),
          );
          planningCTX.setResource(
            rooms

              .map((e) => {
                return { ...e, key: e.Id, id: e.Id };
              })
              .sort((a, b) => a.PositionRoom - b.PositionRoom),
          );

          break;
        case 1:
        case 3:
          const arrayOfRooms = rooms.map((e) => {
            return { ...e, key: e.Id, id: e.Id, Id: e.Id.replace(' ', '').replace('°', '') };
          });
          const groupedRooms = arrayOfRooms.reduce((acc: RoomAccumulator, room) => {
            let type = room.RoomType;
            if (room.IdLinkedRoom !== undefined) {
              type = 'COMMUNICANTE';
            }
            if (!acc[type]) {
              acc[type] = [];
            }
            acc[type].push(room);
            return acc;
          }, {});
          const resources = Object.keys(groupedRooms)
            .map((type, index) => {
              const children = groupedRooms[type]

                .map((room: Room) => ({
                  ...room,
                }))
                .sort((a, b) => a.PositionRoom - b.PositionRoom); // Tri des enfants par RoomPosition

              return {
                id: 'p' + (index + 1).toString(),
                Id: (index + 1).toString(),
                key: (index + 1).toString(),
                RoomNumber: type,
                PositionRoomType: type === 'VIRTUAL' ? 2000 : groupedRooms[type][0].RoomTypeOrder,
                PositionRoom: 0,
                RoomType: 'parent',
                collapsed:
                  filtersCTX.filters.planningFilters.CurrentSort === 3
                    ? false
                    : filtersCTX.filters.planningFilters.IsCollapse,
                icon: '\u2615\uFE0F',
                eventCreation: false,
                children: children,
              };
            })
            .sort((a, b) => {
              return a.PositionRoomType - b.PositionRoomType;
            });
          setMyResources(resources);

          planningCTX.setResource(resources);
          break;
        case 2:
          const arrayOfRooms2 = rooms.map((e) => {
            return { ...e, key: e.Id, id: e.Id };
          });
          const roomMap = new Map<string, Room>();
          arrayOfRooms2.forEach((room) => {
            roomMap.set(room.Id, room);
          });
          const sortedRooms = arrayOfRooms2.sort((a, b) => {
            if (a.PositionRoomType !== b.PositionRoomType) {
              return a.PositionRoomType - b.PositionRoomType;
            }
            return a.PositionRoom - b.PositionRoom;
          });

          // Create a final sorted array considering IdCommunicatingRoom
          const finalSortedRooms: Room[] = [];
          const visited = new Set<string>();

          sortedRooms.forEach((room) => {
            if (!visited.has(room.Id)) {
              finalSortedRooms.push(room);
              visited.add(room.Id);
              if (room.IdCommunicatingRoomType) {
                const communicatingRoom = roomMap.get(room.IdLinkedRoom ?? '');
                if (communicatingRoom && !visited.has(communicatingRoom.Id)) {
                  finalSortedRooms.push(communicatingRoom);
                  visited.add(communicatingRoom.Id);
                }
              }
            }
          });
          setMyResources(finalSortedRooms);
          planningCTX.setResource(finalSortedRooms);

          break;
        case 4:
          setMyResources(
            rooms
              .filter(
                (e) =>
                  filtersCTX.filters.planningFilters.TypeToDisplay &&
                  filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
              )
              .map((e) => {
                return { ...e, key: e.Id, id: e.Id };
              }),
          );
          planningCTX.setResource(
            rooms
              .filter(
                (e) =>
                  filtersCTX.filters.planningFilters.TypeToDisplay &&
                  filtersCTX.filters.planningFilters.TypeToDisplay.includes(e.RoomType),
              )
              .map((e) => {
                return { ...e, key: e.Id, id: e.Id };
              }),
          );

          break;
        default:
          setMyResources(
            rooms.map((e) => {
              return { ...e, key: e.Id, id: e.Id };
            }),
          );
          planningCTX.setResource(
            rooms.map((e) => {
              return { ...e, key: e.Id, id: e.Id };
            }),
          );

          break;
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersCTX, filtersCTX.filters.planningFilters, rooms, PlanningDate]);

  //création des reservations
  useEffect(() => {
    if (planning) {
      setEvent([
        ...planning
          .filter((t) => t.IdRoom)
          .filter((x) => {
            if (filtersCTX.filters.planningFilters.showDeparture) {
              return x;
            }
            if (
              new Date(moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD')) >=
              new Date(moment(selectedHotel?.CurrentPlanningDate).format('YYYY-MM-DD'))
            ) {
              if (x.Status !== 'D') {
                return x;
              }
            } else {
              return x;
            }
          })
          //  .filter((t) => t.Status !== 'D')
          .map((e) => {
            return {
              ...e,
              id: e.Id,
              memo: e.Memo,
              start: moment(e.Start).format('YYYYMMDD'),
              end: moment(e.End).format('YYYYMMDD'),
              originalBackground: e.BackgroundEvent ?? '',
              originalForeground: e.ForegroundEvent ?? '',
              resource: e.IdRoom,
              editable: !e.Bloque,
            };
          }),
      ]);
    }
  }, [
    planning,
    filtersCTX.filters.planningFilters.showDeparture,
    filtersCTX.filters.planningFilters.DateStart,
    selectedHotel?.CurrentPlanningDate,
  ]);

  useEffect(() => {
    const tempColor: PlanningColor[] = [];
    if (myResources && filtersCTX.filters.planningFilters) {
      if (filtersCTX.filters.planningFilters.CurrentSort === 1) {
        /*    myResources.map((e) => {
          if (e.children) {
            tempColor.push({
              background: hexToRgba(e.children[0].TypeColor ?? '', '0.35'),
              recurring: {
                repeat: 'weekly',
                weekDays: 'MO,TU,WE,TH,FR',
              },
              resource: [...e.children.map((t) => t.id), e.id],
            });
          }
          tempColor.push({
            background: '#333',
            recurring: {
              repeat: 'weekly',
              weekDays: 'MO,TU,WE,TH,FR',
            },
            resource: e.Id,
          });
        });
      } else {
        myResources.map((e) => {
          tempColor.push({
            background: hexToRgba(e.TypeColor ?? '', '0.35'),
            recurring: {
              repeat: 'weekly',
              weekDays: 'MO,TU,WE,TH,FR',
            },
            resource: e.id,
          });
        });
     */
      }

      myResources.map((e) => {
        if (e.children) {
          tempColor.push({
            background: '#dfdfdf',
            recurring: {
              repeat: 'weekly',
              weekDays: 'SU,SA',
            },
          });
        } else {
          tempColor.push({
            background: '#dfdfdf',
            recurring: {
              repeat: 'weekly',
              weekDays: 'SU,SA',
            },
          });
        }
      });
      setMyPlanningColor(tempColor);
    }
  }, [filtersCTX.filters.planningFilters, myResources]);

  //Ajout des lignes de totaux en vue type
  useEffect(() => {
    if (filtersCTX.filters.planningFilters) {
      if (
        event.length > 0 &&
        myResources.length > 0 &&
        filtersCTX.filters.planningFilters.CurrentSort === 1 &&
        planningDetail &&
        filtersCTX.filters.planningFilters &&
        myResources.filter((m) => m.RoomType === 'parent').length > 0 &&
        !event.find((e) => e.totalType === true)
      ) {
        const arrayOfTotal: PlanningLine[] = [];
        for (
          let i = 0;
          i <=
          getDeltaInDays(
            filtersCTX.filters.planningFilters.DateStart as number,
            filtersCTX.filters.planningFilters.DateEnd as number,
          );
          i++
        ) {
          myResources.forEach((item) => {
            const dayTotal = {
              start: moment(new Date(filtersCTX.filters.planningFilters.DateStart)).add(i, 'day').format('YYYYMMDD'),
              end: moment(new Date(filtersCTX.filters.planningFilters.DateStart)).add(i, 'day').format('YYYYMMDD'),
              resource: item.id,
              totalType: true,
              editable: false,
              id:
                item.id +
                moment(new Date(filtersCTX.filters.planningFilters.DateStart)).add(i, 'day').format('YYYYMMDD'),
              Id:
                item.id +
                moment(new Date(filtersCTX.filters.planningFilters.DateStart)).add(i, 'day').format('YYYYMMDD'),
              IdRoom: item.id.toString(),
              DisplayName: countNumberOfEventForResource(item.Id, i)?.toString() ?? '',
              foreground: item?.children?.find((e) => e.TypeColor)?.TypeColor ?? 'red',
            };
            arrayOfTotal.push(dayTotal);
          });
        }

        setEvent([...event].concat(arrayOfTotal));
      }
    }
  }, [
    myResources,
    filtersCTX,
    filtersCTX.filters.planningFilters,
    filtersCTX.filters.planningFilters.CurrentSort,
    planningDetail,
    event,
    countNumberOfEventForResource,
  ]);

  const renderCustomResource = useCallback((resource: Room) => {
    return <CustomResource resource={resource}></CustomResource>;
  }, []);

  const renderCustomResourceBreakingType = useCallback((resource: Room) => {
    if (resource) {
      return <CustomResourceBreakingType resource={resource}></CustomResourceBreakingType>;
    }
  }, []);

  const renderResourceFooter = useCallback(() => {
    const hideNA = planningDetail?.Data.filter((e) => e.DataType === 16).length === 0 ? true : false;
    const hideSR = planningDetail?.Data.filter((e) => e.DataType === 17).length === 0 ? true : false;

    return <ResourceFooter hideSR={hideSR} hideNA={hideNA}></ResourceFooter>;
  }, [planningDetail]);

  const renderCustomDayFooter = useCallback(
    (args: MbscCalendarDayData) => {
      const hideNA = planningDetail?.Data.filter((e) => e.DataType === 16).length === 0 ? true : false;
      const hideSR = planningDetail?.Data.filter((e) => e.DataType === 17).length === 0 ? true : false;

      return (
        <PlanningFooter
          planningDetail={planningDetail}
          hideSR={hideSR}
          hideNA={hideNA}
          date={args.date}
        ></PlanningFooter>
      );
    },
    [planningDetail],
  );

  const dateTemplate = useCallback(
    (date: CalendarDateTemplateEvent) => {
      const dateMoment = moment(date);

      if (dateMoment.isSame(moment(selectedHotel?.CurrentPlanningDate), 'day')) {
        // Applique un style personnalisé si la date correspond à la date de facturation
        return <span style={{ backgroundColor: '#ffeb3b', borderRadius: '50%' }}>{moment(date).date()}</span>;
      }

      // Rendu par défaut pour les autres dates
      return moment(date).date();
    },
    [selectedHotel?.CurrentPlanningDate],
  );

  const renderDay = useCallback(
    (args: MbscCalendarDayData) => {
      return <CustomDay args={args} date={args.date} zoneEvents={zoneEvents}></CustomDay>;
    },
    [zoneEvents],
  );
  const renderResourceHeader = useCallback(() => {
    return (
      <div
        style={{
          height: '37px',
          alignItems: 'center',
          display: 'flex',
          backgroundColor: '#e6effc',
        }}
      >
        <div>
          <Calendar
            touchUI={isMobile}
            todayButtonClassName="toto"
            inputStyle={{
              backgroundColor: 'transparent',
              width: '150px',
              display: 'flex',
              border: 'none',
              marginTop: '-20px',
              color: 'transparent',
              cursor: 'pointer',
            }}
            style={{
              position: 'fixed',
              width: '160px',
              zIndex: '999',
              backgroundColor: 'transparent',
              cursor: 'pointer',
            }}
            viewDate={new Date(selectedHotel?.CurrentPlanningDate as string)}
            locale={userCTX.authenticationInfos?.language || 'fr'}
            value={filtersCTX.filters.planningFilters.DateStart as Date}
            dateTemplate={dateTemplate}
            onChange={(e) => {
              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                planningFilters: {
                  ...prevState.planningFilters,
                  DateStart: moment(e.value?.getTime()).toDate(),
                  DateEnd: moment(e.value).add(prevState.planningFilters.dayToDisplay, 'days').toDate(),
                  needReload: true,
                },
              }));
            }}
          />
        </div>

        <label
          htmlFor="selectDate"
          onClick={() => setShowCalendar(!showCalendar)} // Gestionnaire de clic pour afficher/masquer le calendrier
          style={{
            justifyContent: 'center',
            display: 'flex',
            width: '100%',
            fontWeight: 'bold',
            textTransform: 'uppercase',
            alignItems: 'center',
            margin: 0,
            fontSize: '12pt',
            padding: 0,
            backgroundColor: '#e6effc',
          }}
        >
          {new Date(filtersCTX.filters.planningFilters.DateStart).toLocaleDateString(
            userCTX.authenticationInfos.language,
            {
              month: 'long',
              year: 'numeric',
            },
          )}
        </label>
      </div>
    );
  }, [
    dateTemplate,
    filtersCTX,
    selectedHotel?.CurrentPlanningDate,
    showCalendar,
    userCTX.authenticationInfos.language,
  ]);
  const myScheduleEvent = useCallback(
    (data: MbscCalendarEventData) => {
      return <Reservation data={data} PlanningDate={PlanningDate} />;
    },
    [PlanningDate],
  );

  const hoverTimerRef = useRef<number | null>(null);

  const debouncedSetEventRef = useRef(
    debounce((modifiedEvents) => {
      setEvent(modifiedEvents);
    }, 200),
  ).current;

  const onEventHoverIn = useCallback(
    (args: MbscEventClickEvent) => {
      const modifyEvents = (args: MbscEventClickEvent, backgroundKey: string, foregroundKey: string) => {
        return event.map((e) => {
          if (e.IsGroup === args.event.IsGroup && e.RefGroup === args.event.RefGroup && e.IsGroup === true) {
            return {
              ...e,
              BackgroundEvent: args.event[backgroundKey],
              ForegroundEvent: args.event[foregroundKey],
            };
          }
          return e;
        });
      };

      // Annuler le temporisateur précédent s'il existe
      if (hoverTimerRef.current) {
        clearTimeout(hoverTimerRef.current);
      }

      // Définir un nouveau temporisateur de 3 secondes
      hoverTimerRef.current = window.setTimeout(() => {
        if (args.event.IsGroup) {
          const modifiedEvents = modifyEvents(args, 'ForegroundEvent', 'BackgroundEvent');
          debouncedSetEventRef(modifiedEvents);
        }
      }, 200); // Attendre 3 secondes
    },
    [debouncedSetEventRef, event],
  );

  const onEventHoverOut = useCallback(
    (args: MbscEventClickEvent) => {
      const modifyEvents = (args: MbscEventClickEvent, backgroundKey: string, foregroundKey: string) => {
        return event.map((e) => {
          if (e.IsGroup === args.event.IsGroup && e.RefGroup === args.event.RefGroup && e.IsGroup === true) {
            return {
              ...e,
              BackgroundEvent: args.event[backgroundKey],
              ForegroundEvent: args.event[foregroundKey],
            };
          }
          return e;
        });
      };

      // Annuler le temporisateur si l'utilisateur quitte le survol avant 3 secondes
      if (hoverTimerRef.current) {
        clearTimeout(hoverTimerRef.current);
        hoverTimerRef.current = null;
      }

      // Réinitialiser les événements immédiatement
      const modifiedEvents = modifyEvents(args, 'originalBackground', 'originalForeground');
      setEvent(modifiedEvents);
    },
    [event, setEvent],
  );
  /*




  const onEventDragEnd = useCallback(
    (args: MbscEventDragEvent) => {
      userCTX.SendToGroup(JSON.stringify(args.event), userCTX.authenticationInfos);
      // setLine({ ...line, visible: false });
    },
    [userCTX],
  );

 
*/
  const onEventClick = useCallback(
    (args: MbscEventClickEvent) => {
      if (!args.event.totalType) {
        bookingCTX.getBookingSummary({ params: { selectedHotel }, idBooking: args.event.id ?? '' }).then((data) => {
          const form = windowCTX.window.listOfWindowsDisplayed.find((e) => e.id === 10000);
          if (!form) {
            const id = 10000;
            const newWindow = {
              id: id, // ou utilisez un ID unique
              component: LightBookingSummary, // Remplacez par le composant que vous souhaitez afficher
              data: data, // Les données que vous souhaitez transmettre au composant
              translatedTitle: (
                <>
                  <i style={{ color: 'darkorange', marginRight: '5px' }} className="fa-solid fa-hashtag"></i>
                  {data?.ConfirmationNumber}
                  {data?.RefGroup && (
                    <>
                      <i style={{ marginLeft: '10px', marginRight: '5px' }} className="fa-solid fa-users"></i>
                      {data?.RefGroup + ` (${data.GroupRoomsQty})`}
                    </>
                  )}
                  {data?.HasRoomAccessCode && <i className="fa-solid fa-key"></i>}
                  {data?.HasWakeUp && <i className="fa-regular fa-clock"></i>}
                  {data?.MediaScanId && <i className="fa-solid fa-id-card"></i>}
                  {data?.CommunicatingRoomNumber !== undefined && (
                    <>
                      <i className="fa-solid fa-link"></i>
                      {data?.CommunicatingRoomNumber}
                    </>
                  )}
                </>
              ),
              actions: { hideSubForm: () => windowCTX.hideWindow(id) },
              draggable: true, // Définissez-le en fonction de vos besoins
              border: '2px solid #666',
              fullWidth: true, // Définissez-le en fonction de vos besoins
              fullHeight: true, // Définissez-le en fonction de vos besoins
              tabMode: true,
              x: 'calc(100vw - 475px)',
              y: 0,
              width: '400px',
            };

            // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
            windowCTX.setWindow((prevWindow) => ({
              ...prevWindow,
              listOfWindowsDisplayed: [...prevWindow.listOfWindowsDisplayed, newWindow],
            }));
          } else {
            windowCTX.setWindow((prevWindow) => {
              const updatedForms = prevWindow.listOfWindowsDisplayed.map((window) =>
                window.id === 10000
                  ? {
                      ...window,
                      classToAdd: 'showWindow',
                      data: data, // Les données que vous souhaitez transmettre au composant
                      translatedTitle: (
                        <>
                          <i style={{ color: 'darkorange', marginRight: '5px' }} className="fa-solid fa-hashtag"></i>
                          {data?.ConfirmationNumber}
                          {data?.RefGroup && <i className="fa-solid fa-users"></i>}
                          {data?.HasRoomAccessCode && <i className="fa-solid fa-key"></i>}
                          {data?.HasWakeUp && <i className="fa-regular fa-clock"></i>}
                          {data?.MediaScanId && <i className="fa-solid fa-id-card"></i>}
                          {data?.CommunicatingRoomNumber !== undefined && (
                            <>
                              <i className="fa-solid fa-link"></i>
                              {data?.CommunicatingRoomNumber}
                            </>
                          )}
                        </>
                      ),
                    }
                  : form,
              );
              return { ...prevWindow, listOfWindowsDisplayed: updatedForms };
            });
          }
        });
      }
    },
    [bookingCTX, selectedHotel, windowCTX],
  );
  const handleUpdate = useCallback(
    (event: number) => {
      switch (event) {
        case 0:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              CurrentSort: 0,
              ForceOneDay: false,
            },
          }));
          break;
        case 1:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              CurrentSort: 1,
              ForceOneDay: false,
            },
          }));
          break;
        case 2:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              IsCollapse: !prevState.planningFilters.IsCollapse,
            },
          }));
          break;
        case 3:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              IsCollapse: false,
              ForceOneDay: true,
              CurrentSort: 3,
            },
          }));
          break;
        case 4:
          filtersCTX.setFilters((prevState) => ({
            ...prevState,
            planningFilters: {
              ...prevState.planningFilters,
              ForceOneDay: true,
              IsCollapse: false,
              CurrentSort: 4,
            },
          }));
          break;
      }
    },
    [filtersCTX],
  );

  return (
    <>
      <div className="row">
        <div className="col-lg-12 col-12">
          {(isMobile || isTablet || isConditionMet) && (
            <div
              className={`small-box`}
              style={{ width: isPortrait ? '100%' : '50%', padding: '10px', background: '#f8f9fa' }}
            >
              <div className="inner">{<PlanningFilters></PlanningFilters>}</div>
            </div>
          )}
        </div>
      </div>
      {myResources && filtersCTX.filters.planningFilters && event.length >= 0 && zoneEvents !== null && rooms && (
        <>
          {filtersCTX.filters.planningFilters.CurrentSort != 4 && (
            <Eventcalendar
              theme="material"
              themeVariant="light"
              locale={
                userCTX.authenticationInfos.language === 'fr'
                  ? localeFr
                  : userCTX.authenticationInfos.language === 'en'
                    ? localeEn
                    : userCTX.authenticationInfos.language === 'es'
                      ? localeEs
                      : userCTX.authenticationInfos.language === 'de'
                        ? localeDe
                        : userCTX.authenticationInfos.language === 'ma'
                          ? localeAr
                          : userCTX.authenticationInfos.language === 'cn'
                            ? localeZh
                            : ''
              }
              rtl={userCTX.authenticationInfos.language === 'ma' ? true : false}
              showEventTooltip={false}
              height={'93vh'}
              onDayHoverIn={() => null}
              className={darkMode ? 'darkmode' : 'lightmode'}
              eventOverlap={false}
              renderDayFooter={renderCustomDayFooter}
              onEventUpdateFailed={() => toast.error('Collision entre deux réservations')}
              view={{
                timeline: {
                  type: 'day',
                  virtualScroll: false,
                  resolutionHorizontal: 'day',
                  currentTimeIndicator: false,
                  size: filtersCTX.filters.planningFilters.ForceOneDay
                    ? 1
                    : getDeltaInDays(
                        filtersCTX.filters.planningFilters.DateStart as number,
                        filtersCTX.filters.planningFilters.DateEnd as number,
                      ) + 1,
                  eventList: true,
                },
              }}
              refDate={moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD')}
              colors={myPlanningColor}
              data={event}
              resources={myResources}
              renderResourceFooter={renderResourceFooter}
              renderResource={
                filtersCTX.filters.planningFilters.CurrentSort === 1 ||
                filtersCTX.filters.planningFilters.CurrentSort === 3
                  ? renderCustomResourceBreakingType
                  : renderCustomResource
              }
              renderResourceHeader={renderResourceHeader}
              dragBetweenResources={false}
              dragInTime={false}
              dragToMove={false}
              dragTimeStep={1}
              dragToResize={false}
              renderScheduleEvent={myScheduleEvent}
              selectedDate={moment(filtersCTX.filters.planningFilters.DateStart).format('YYYY-MM-DD')}
              renderDay={renderDay}
              onEventClick={onEventClick}
              //  onEventDragEnd={onEventDragEnd}
              onEventHoverIn={onEventHoverIn}
              onEventHoverOut={onEventHoverOut}
              onSelectedDateChange={(event) => {
                planningCTX.getPlanningDetail(filtersCTX.filters.planningFilters);
                planningCTX.getZoneEvents(filtersCTX.filters.planningFilters);

                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  planningFilters: {
                    ...prevState.planningFilters,
                    DateStart: new Date(event.date as Date).getTime(),
                    DateEnd: moment(new Date(event.date as Date))
                      .add(21, 'day')
                      .toDate()
                      .getTime(),
                  },
                }));
                planningCTX.getPlanning(filtersCTX.filters.planningFilters);
              }}
            ></Eventcalendar>
          )}
          {filtersCTX.filters.planningFilters.CurrentSort === 4 && (
            <Point
              updateParentMethod={handleUpdate}
              renderDate={moment(userCTX.authenticationInfos.selectedHotel?.CurrentPlanningDate).format('YYYY-MM-DD')}
            ></Point>
          )}
        </>
      )}
    </>
  );
};

export default Planning;
