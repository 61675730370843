import React, { useEffect, useState } from 'react';

import MenuItem from '@app/components/Common/menu-item/MenuItem';
import { useUser } from '@app/modules/user/UserContext';
import { RootState } from '@app/store/store';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useFilters } from '@app/modules/filter/filterContext';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import moment from 'moment-timezone';
import { toggleSidebarMenu } from '@app/store/reducers/ui';

export interface IMenuItem {
  name: string;
  icon?: string;
  path?: string;
  children?: Array<IMenuItem>;
  downloadPath?: string;
  type?: string;
  code?: string;
  level?: string;
  CanAccess?: boolean;
  PermissionId?: number;
  setIsMenuHovered?: () => void;
}

const MenuSidebar: React.FC = () => {
  const userCTX = useUser();
  const authData = userCTX.authenticationInfos;
  const [isMenuHovered, setIsMenuHovered] = useState(false);
  const dispatch = useDispatch();

  const sidebarSkin = useSelector((state: RootState) => state.ui.sidebarSkin);
  const menuItemFlat = useSelector((state: RootState) => state.ui.menuItemFlat);
  const menuChildIndent = useSelector((state: RootState) => state.ui.menuChildIndent);
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const hotelList = userCTX.authenticationInfos.listHotel;

  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const [t] = useTranslation();

  const menu: IMenuItem[] = [
    {
      name: t('menusidebar.label.dashboard'),
      icon: 'fas fa-tachometer-alt nav-icon blue-icon',
      path: isTech ? '/dashboard' : '/',
    },

    ...(window.location.origin.includes('localhost') || window.location.origin.includes('webdispo-dev')
      ? [
          {
            icon: 'nav-icon fas fa-key blue-icon',

            name: 'DEV',
            path: '/dev',
          },
        ]
      : []),
    ...(isTech
      ? [
          {
            name: t('menusidebar.label.admin'),
            icon: 'nav-icon fa-solid fa-screwdriver-wrench blue-icon',
            path: '/',
          },
        ]
      : []),
  ];

  const menuHotel: IMenuItem[] = [
    {
      name: t('menusidebar.label.hotel'),
      icon: 'fas fa-hotel nav-icon blue-icon',
      path: '#',
    },

    {
      name: t('menusidebar.label.planning'),
      icon: 'fas fa-calendar-alt nav-icon blue-icon',
      path: '/planning',
    },

    {
      name: t('menusidebar.label.reservation'),
      icon: 'fas fa-book-open-reader nav-icon blue-icon',
      children: [
        {
          name: t('menusidebar.label.libre'),
          icon: 'fas fa-book-open-reader nav-icon blue-icon',
          setIsMenuHovered: () => handleToggleMenuSidebar(),
        },
      ],
    },
  ];

  const menuAnalytics: IMenuItem[] = [
    {
      icon: 'nav-icon fas fa-th blue-icon',

      name: t('menusidebar.label.prevision'),
      path: '/prevision',
    },
    {
      name: t('menusidebar.label.repartition'),
      icon: 'fas fa-solid fa-chart-pie nav-icon blue-icon',
      path: '/repartition',
    },
    {
      name: t('menusidebar.label.activityPerformance'),
      icon: 'fas fa-calendar-alt nav-icon blue-icon',
      path: '/performance',
    },
    {
      icon: 'fas fa-print nav-icon blue-icon',

      name: t('menusidebar.label.maincourante'),
      path: '/maincourante',
    },

    {
      name: t('menusidebar.label.pickup'),
      icon: 'fa-solid fa-chart-simple  nav-icon blue-icon',
      path: '/pickup',
    },
  ];

  const support: IMenuItem = {
    name: 'Support',
    icon: 'fas fa-headset nav-icon blue-icon',
    downloadPath: ' ./Assistance-Medialog.exe',
    setIsMenuHovered: () => handleToggleMenuSidebar(),
  };

  const [menuTodisplay, setMenuToDisplay] = useState<IMenuItem[]>(menu);
  const handleMouseEnter = () => {
    setIsMenuHovered(true);
  };

  const handleMouseLeave = () => {
    setIsMenuHovered(false);
  };
  useEffect(() => {}, [isMenuHovered]);
  useEffect(() => {
    menuHotel[0].path = '/hotel/' + selectedHotel?.IdHotel;
    menuHotel[0].name = selectedHotel?.Name ?? '';

    if (authData.userMenu) {
      menuHotel.push({
        name: 'Installation',
        icon: 'fas fa-hammer nav-icon blue-icon',
        children: authData.userMenu.map((e) => {
          if (e.CanAccess === false) {
            return {
              name: e.Libelle,
              code: e.EventsId,
              type: 'installation',
              icon: 'fas fa-angles-right small-icon nav-icon fullfilled-icon',
              CanAccess: e?.CanAccess ?? false,
              PermissionId: e?.PermissionId,

              children: e.SubItems.map((f) => {
                return {
                  name: f.Libelle,
                  code: f.EventsId,
                  CanAccess: f?.CanAccess ?? false,
                  PermissionId: f.PermissionId,
                  type: 'installation',
                  icon: 'fas fa-circle extraSmall-icon  fa-2xs nav-icon',
                  setIsMenuHovered: () => handleToggleMenuSidebar(),
                };
              }),
            };
          } else if (e.SubItems.length > 0) {
            return {
              name: e.Libelle,
              code: e.EventsId,
              type: 'installation',
              icon: 'fas fa-angles-right small-icon  nav-icon fullfilled-icon',
              PermissionId: e?.PermissionId,

              children: e.SubItems.map((f) => {
                return {
                  name: f.Libelle,
                  code: f.EventsId,
                  CanAccess: e?.CanAccess ?? false,
                  PermissionId: e?.PermissionId,

                  type: 'installation',
                  icon: 'fas fa-circle extraSmall-icon  fa-2xs nav-icon ',
                  setIsMenuHovered: () => handleToggleMenuSidebar(),

                  level: f.EventsIdLevel,
                };
              }),
            };
          } else {
            return {
              name: e.Libelle,
              code: e.EventsId,
              type: 'installation',
              CanAccess: e?.CanAccess ?? false,
              PermissionId: e?.PermissionId,
              setIsMenuHovered: () => handleToggleMenuSidebar(),

              icon: 'far  fa-greater-than small-icon  fa-2xs  nav-icon blue-icon',
            };
          }
        }),
      });
    }
    setMenuToDisplay([...menuHotel]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authData.userMenu, selectedHotel]);
  const handleToggleMenuSidebar = () => {
    dispatch(toggleSidebarMenu());
  };

  return (
    <aside
      onMouseEnter={handleMouseEnter}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={`nav ${isMenuHovered ? 'menu-hover' : ''} main-sidebar elevation-4 ${sidebarSkin}`}
    >
      <Link to="/" className="brand-link" style={{ display: 'flex', margin: 0, padding: 10, alignItems: 'center' }}>
        <img src="/img/logo.png" alt="Webdispo" width={35} />
        <span className="brand-text font-weight-light">
          <h4 style={{ margin: 0 }}>WebDispo</h4>
        </span>
      </Link>
      <div
        id="sidebar"
        className={`sidebar  ${isMenuHovered ? 'menu-hover' : ''}`}
        onMouseEnter={handleMouseEnter}
        style={{ maxHeight: 'calc(100vh - 90px)' }}
      >
        <nav className="mt-2" style={{ overflow: 'hidden' }}>
          <Accordion activeIndex={hotelList?.length === 1 ? 0 : selectedHotel ? 1 : 0}>
            <AccordionTab
              className="groupe GroupMenu"
              header={hotelList?.length === 1 ? t('menusidebar.label.hotel') : t('menusidebar.label.groupe')}
            >
              <ul
                onMouseEnter={handleMouseEnter}
                className={`nav nav-pills nav-sidebar  ${isMenuHovered ? 'menu-hover' : ''}  flex-column${
                  menuItemFlat ? ' nav-child-indent' : ' nav-child-indent'
                }${menuChildIndent ? ' nav-child-indent' : ' nav-child-indent'}`}
                role="menu"
              >
                {menu.map((menuItem: IMenuItem) => {
                  menuItem.setIsMenuHovered = () => handleToggleMenuSidebar();

                  return <MenuItem key={menuItem.name + menuItem.path} menuItem={menuItem} />;
                })}
              </ul>
              {hotelList?.length === 1 && (
                <>
                  <ul
                    className={`nav nav-blue nav-pills nav-sidebar ${isMenuHovered ? 'menu-hover' : ''} flex-column${
                      menuItemFlat ? ' nav-child-indent' : ' nav-child-indent'
                    }${menuChildIndent ? ' nav-child-indent' : ' nav-child-indent'}`}
                    role="menu"
                  >
                    {menuTodisplay.map((menuItem: IMenuItem) => {
                      menuItem.setIsMenuHovered = () => handleToggleMenuSidebar();
                      return <MenuItem key={menuItem.name + menuItem.path} menuItem={menuItem} />;
                    })}
                  </ul>
                </>
              )}
            </AccordionTab>

            {selectedHotel && hotelList && hotelList?.length > 1 && (
              <AccordionTab className="groupe HotelMenu" header={t('menusidebar.label.hotel')} tabIndex={1}>
                <>
                  <ul
                    className={`nav nav-blue nav-pills nav-sidebar ${isMenuHovered ? 'menu-hover' : ''} flex-column${
                      menuItemFlat ? ' nav-child-indent' : ' nav-child-indent'
                    }${menuChildIndent ? ' nav-child-indent' : ' nav-child-indent'}`}
                    role="menu"
                  >
                    {menuTodisplay.map((menuItem: IMenuItem) => {
                      menuItem.setIsMenuHovered = () => handleToggleMenuSidebar();
                      return <MenuItem key={menuItem.name + menuItem.path} menuItem={menuItem} />;
                    })}
                  </ul>
                </>
              </AccordionTab>
            )}
            <AccordionTab className="groupe AnalyticsMenu" header={t('menusidebar.label.analytics')}>
              <>
                <ul
                  className={`nav nav-blue nav-pills nav-sidebar flex-column${
                    menuItemFlat ? ' nav-child-indent' : ' nav-child-indent'
                  }${menuChildIndent ? ' nav-child-indent' : ' nav-child-indent'}`}
                  role="menu"
                >
                  {menuAnalytics.map((menuItem: IMenuItem) => {
                    menuItem.setIsMenuHovered = () => handleToggleMenuSidebar();

                    return <MenuItem key={menuItem.name + menuItem.path} menuItem={menuItem} />;
                  })}
                </ul>
              </>
            </AccordionTab>
          </Accordion>
          <div className="hotel" style={{}}>
            <ul
              className={`nav nav-blue nav-pills nav-sidebar flex-column${
                menuItemFlat ? ' nav-child-indent' : ' nav-child-indent'
              }${menuChildIndent ? ' nav-child-indent' : ' nav-child-indent'}`}
            >
              <MenuItem key={'support'} menuItem={support} />
            </ul>
          </div>
        </nav>
      </div>
    </aside>
  );
};

export default MenuSidebar;
