import { useEffect, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { storage } from '@app/store/localstorage/localStorage';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import moment from 'moment';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { Button } from 'primereact/button';
import { Calendar, CalendarDateTemplateEvent } from 'primereact/calendar';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { SelectButton } from 'primereact/selectbutton';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CalendarSelector from '../calendar/CalendarSelector';

const PrevisionFilters: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const [isLocaleSet, setIslocaleSet] = useState(false);
  const filtersCTX = useFilters();
  const previsionFilters = filtersCTX.filters.previsionFilters;
  const userCTX = useUser();
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const authData = userCTX.authenticationInfos;
  const hotelList = userCTX.authenticationInfos.listHotel;
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  useEffect(() => {
    if (isTech && selectedHotel) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        previsionFilters: {
          ...prevState.previsionFilters,
          needReload: true,
        },
      }));
    }
    if (hotelList?.length === 1 && previsionFilters !== null) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        previsionFilters: {
          ...prevState.previsionFilters,
          ListHotels: hotelList,
          needReload: true,
        },
      }));
    } else {
      if (selectedHotel) {
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          previsionFilters: {
            ...prevState.previsionFilters,
            ListHotels: [selectedHotel as HotelRight],
            needReload: true,
          },
        }));
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelList]);

  const [t] = useTranslation();
  const cumulMode = [
    { name: t('prevision.label.day'), code: 0 },
    { name: t('prevision.label.Country'), code: 1 },
    { name: t('prevision.label.Segment'), code: 2 },
    { name: t('prevision.label.Origine'), code: 3 },
    { name: t('prevision.label.numberRooms'), code: 4 },
    { name: t('prevision.label.Type'), code: 5 },
    { name: t('prevision.label.Hebergement'), code: 6 },
    { name: t('prevision.label.CodeTarif'), code: 7 },
    { name: t('prevision.label.Distributeur'), code: 8 },
    { name: t('prevision.label.TypeStandard'), code: 9 },
    { name: t('prevision.label.NomTypeStandard'), code: 10 },
  ];
  const priceOptions = [t('labels.HT'), t('labels.TTC')];

  const customHeader = (options: MultiSelectPanelHeaderTemplateEvent) => {
    const onHeaderCheckboxChange = (e: any) => {
      // e.checked donne l'état actuel de la case à cocher
      // Utilisez cette valeur pour gérer le changement
      options.onChange(e.checked);
    };
    return (
      <div className="p-multiselect-header" style={{ alignItems: 'center', marginTop: '10px' }}>
        <div className="p-checkbox p-component">
          <input
            style={{
              border: '2px solid #ced4da',
              background: '#ffffff',
              width: '20px',
              height: '20px',
              color: '#495057',
              borderRadius: '4px',

              transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
            }}
            type="checkbox"
            role="checkbox"
            checked={options.checked}
            onChange={(e) => onHeaderCheckboxChange({ originalEvent: e, checked: e.target.checked })}
          />
        </div>
        <span style={{ marginLeft: '10px' }}>Tous</span>
      </div>
    );
  };

  const dateTemplate = (event: CalendarDateTemplateEvent) => {
    const currentDate: Date = new Date(event.year, event.month, event.day);
    if (previsionFilters.DateStart && currentDate < previsionFilters.DateStart) {
      return <strong style={{ textDecoration: 'line-through', opacity: '0.2', color: 'red' }}>{event.day}</strong>;
    }
    return event.day; // default behavior
  };
  useEffect(() => {
    setIslocaleSet(false);
    if (authData.language) {
      setIslocaleSet(true);
    }
  }, [authData.language]);

  return (
    <div
      style={{
        display: !isMobile ? 'flex' : '',
        flexDirection: isMobileOnly ? (orientation === 'portrait' ? 'column' : 'row') : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLocaleSet && previsionFilters && isMobile && (
        <Accordion activeIndex={0}>
          <AccordionTab header={t('labels.filters')} tabIndex={0}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                backgroundColor: 'transparent',
                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
                color: colors.colorGreyAdminLTE,
                padding: '10px',
              }}
            >
              <div style={{ width: '100%', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    height: '50px',
                    paddingRight: '20px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {' '}
                  <div style={{ width: '100%' }}>
                    <CalendarSelector
                      numberOfMonthtoDisplay={1}
                      data={{ DateStart: previsionFilters.DateStart, DateEnd: previsionFilters.DateEnd }}
                      updateParentFilter={(start: number | string | Date, end: number | string | Date) => {
                        filtersCTX.setFilters((prevState) => ({
                          ...prevState,
                          previsionFilters: {
                            ...prevState.previsionFilters,
                            DateStart: start,
                            DateEnd: end,
                            hasBeenModified: true,
                          },
                        }));
                      }}
                      shouldClose={previsionFilters.needReload}
                      width="100%"
                    ></CalendarSelector>
                  </div>
                </div>
              </div>

              <div style={{ width: '50%', flexDirection: 'column' }}>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'end',
                    height: '50px',
                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  {!isTech && (
                    <MultiSelect
                      value={previsionFilters.ListHotels}
                      panelHeaderTemplate={customHeader}
                      onChange={(e) => {
                        filtersCTX.setFilters((prevState) => ({
                          ...prevState,
                          previsionFilters: {
                            ...prevState.previsionFilters,
                            ListHotels: e.value,
                            hasBeenModified: true,
                          },
                        }));
                      }}
                      options={
                        hotelList?.sort((a, b) => {
                          if (a.Name < b.Name) {
                            return -1;
                          }
                          if (a.Name > b.Name) {
                            return 1;
                          }
                          return 0;
                        }) ?? []
                      }
                      optionLabel="Name"
                      placeholder={t('label.placeholder.choice') ?? ''}
                    />
                  )}
                  {isTech && (
                    <Dropdown
                      value={selectedHotel}
                      onChange={async (e) => {
                        userCTX.setUser((prevState) => ({
                          ...prevState,
                          selectedHotel: e.value ?? '',
                        }));
                        storage.setParam('selectedHotel', JSON.stringify(e.value));
                      }}
                      options={hotelList as HotelData[]}
                      optionLabel="Name"
                      filter
                      placeholder={t('label.placeholder.choice') ?? ''}
                      className="w-full md:w-14rem"
                      style={{ whiteSpace: 'nowrap', maxWidth: '180px' }}
                    />
                  )}
                </div>

                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',

                    flexWrap: 'wrap',
                    alignContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Dropdown
                    value={cumulMode.find((e) => e.code === previsionFilters.ForecastType)}
                    onChange={(e) => {
                      filtersCTX.setFilters((prevState) => ({
                        ...prevState,
                        previsionFilters: {
                          ...prevState.previsionFilters,
                          ForecastType: e.value.code,
                          hasBeenModified: true,
                        },
                      }));
                    }}
                    options={cumulMode}
                    optionLabel="name"
                    placeholder={t('labels.cumulMode') ?? ''}
                    style={{ maxWidth: '190px', overflow: 'hidden' }}
                  />
                </div>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  backgroundColor: 'transparent',
                  flexWrap: 'wrap',
                  alignContent: 'center',
                  alignItems: 'center',
                  flexDirection: 'row',
                  width: '100%',
                  color: colors.colorGreyAdminLTE,
                  padding: '10px',
                }}
              >
                <Checkbox
                  inputId="options"
                  checked={previsionFilters.IncludingOptions}
                  onChange={() => {
                    filtersCTX.setFilters((prevState) => ({
                      ...prevState,
                      previsionFilters: {
                        ...prevState.previsionFilters,
                        IncludingOptions: !previsionFilters.IncludingOptions,
                        hasBeenModified: true,
                      },
                    }));
                  }}
                />

                <label htmlFor="options" style={{ margin: 0, marginRight: '10px', marginLeft: '10px' }}>
                  {t('labels.showOptions')}
                </label>
                <SelectButton
                  value={previsionFilters.IncludingTaxes ? t('labels.TTC') : t('labels.HT')}
                  onChange={(e) => {
                    filtersCTX.setFilters((prevState) => ({
                      ...prevState,
                      previsionFilters: {
                        ...prevState.previsionFilters,
                        IncludingTaxes: e.value === t('labels.TTC') ? true : false,
                        hasBeenModified: true,
                      },
                    }));
                  }}
                  options={priceOptions}
                  style={{ height: '40px' }}
                  height={'40px'}
                />

                <Button
                  style={{
                    marginRight: '10px',
                    marginLeft: '10px',
                    borderRadius: '4px',
                    backgroundColor: filtersCTX.filters.previsionFilters.hasBeenModified
                      ? colors.colorSuccessMain
                      : darkMode
                        ? 'var(--color-primary-light)'
                        : 'var(--color-primary-main)',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                  icon="pi pi-refresh"
                  onClick={() => {
                    filtersCTX.setFilters((prevState) => ({
                      ...prevState,
                      previsionFilters: {
                        ...prevState.previsionFilters,
                        needReload: true,
                        hasBeenModified: false,
                      },
                    }));
                  }}
                ></Button>
              </div>
            </div>
          </AccordionTab>
        </Accordion>
      )}
      {isLocaleSet && previsionFilters && !isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',

            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <CalendarSelector
              numberOfMonthtoDisplay={2}
              data={{ DateStart: previsionFilters.DateStart, DateEnd: previsionFilters.DateEnd }}
              updateParentFilter={(start: number | string | Date, end: number | string | Date) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  previsionFilters: {
                    ...prevState.previsionFilters,
                    DateStart: start,
                    DateEnd: end,
                    hasBeenModified: true,
                  },
                }));
              }}
              shouldClose={previsionFilters.needReload}
              width="100%"
            ></CalendarSelector>
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {!isTech && (
              <MultiSelect
                id="FilterHotel"
                value={previsionFilters.ListHotels}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    previsionFilters: {
                      ...prevState.previsionFilters,
                      ListHotels: e.value,
                      hasBeenModified: true,
                    },
                  }));
                }}
                disabled={hotelList?.length === 1}
                panelHeaderTemplate={customHeader}
                options={
                  hotelList?.sort((a, b) => {
                    if (a.Name < b.Name) {
                      return -1;
                    }
                    if (a.Name > b.Name) {
                      return 1;
                    }
                    return 0;
                  }) ?? []
                }
                optionLabel="Name"
                placeholder={t('label.placeholder.choice') ?? ''}
                style={{ marginRight: '10px', marginLeft: '10px', width: '190px' }}
              />
            )}
            {isTech && (
              <Dropdown
                value={selectedHotel}
                onChange={async (e) => {
                  userCTX.setUser((prevState) => ({
                    ...prevState,
                    selectedHotel: e.value ?? '',
                  }));
                  storage.setParam('selectedHotel', JSON.stringify(e.value));
                }}
                options={hotelList as HotelData[]}
                optionLabel="Name"
                filter
                placeholder={t('label.placeholder.choice') ?? ''}
                className="w-full md:w-14rem"
                style={{ whiteSpace: 'nowrap', maxWidth: '160px', marginRight: '10px', marginLeft: '10px' }}
              />
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Checkbox
              id="ShowOptions"
              inputId="options"
              checked={previsionFilters.IncludingOptions}
              onChange={() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  previsionFilters: {
                    ...prevState.previsionFilters,
                    IncludingOptions: !previsionFilters.IncludingOptions,
                    hasBeenModified: true,
                  },
                }));
              }}
            />

            <label htmlFor="options" style={{ margin: 0, marginRight: '10px', marginLeft: '10px' }}>
              {t('labels.showOptions')}
            </label>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Dropdown
              id="DisplayFilter"
              value={cumulMode.find((e) => e.code === previsionFilters.ForecastType)}
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  previsionFilters: {
                    ...prevState.previsionFilters,
                    ForecastType: e.value.code,
                    hasBeenModified: true,
                  },
                }));
              }}
              options={cumulMode}
              optionLabel="name"
              placeholder={t('labels.cumulMode') ?? ''}
              style={{ marginRight: '10px', marginLeft: '10px', width: '190px' }}
            />
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <SelectButton
                value={previsionFilters.IncludingTaxes ? t('labels.TTC') : t('labels.HT')}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    previsionFilters: {
                      ...prevState.previsionFilters,
                      IncludingTaxes: e.value === t('labels.TTC') ? true : false,
                      hasBeenModified: true,
                    },
                  }));
                }}
                options={priceOptions}
                style={{ height: '40px' }}
                height={'40px'}
              />
            </div>
            <Button
              id="FilterButton"
              disabled={isTech ? false : previsionFilters.ListHotels.length <= 0}
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                borderRadius: '4px',
                backgroundColor: filtersCTX.filters.previsionFilters.hasBeenModified
                  ? colors.colorSuccessMain
                  : darkMode
                    ? 'var(--color-primary-light)'
                    : 'var(--color-primary-main)',

                textAlign: 'center',
                justifyContent: 'center',
              }}
              icon="pi pi-refresh"
              onClick={() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  previsionFilters: {
                    ...prevState.previsionFilters,
                    needReload: true,
                    hasBeenModified: false,
                  },
                }));
              }}
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PrevisionFilters;
