import React, { createContext, ReactNode, useContext, useEffect, useRef, useState } from 'react';

import { config } from '@app/utils/config';
import { getLogger } from 'loglevel';

import axios from '@services/axios/Axios';

import { useMessages } from '../MessageContext';
import { UserType, useUser } from '../user/UserContext';

// Define the type for your params

// Define the interface for ParamsContextState
interface WsContextState {
  data: any;
  isConnected: boolean;
  isInGroup: boolean;
  message: any;
  connectGroup: (IdHotel: number) => Promise<string | undefined>;
  SendToGroup: (message: string, user: UserType) => void;
  getConnectedUser: () => void;
  connectedUser: any;
  setConnectedUser: React.Dispatch<React.SetStateAction<any>>;
}

// Create a context to hold your params with default values
export const WsContext = createContext<WsContextState | undefined>(undefined);

// Define the props type for the ParamsProvider component
type WsProviderProps = {
  children: ReactNode;
};

// Create a provider component
export const WsProvider: React.FC<WsProviderProps> = (props) => {
  const [data, setData] = useState<any>(null);
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const [isInGroup, setIsInGroup] = useState<boolean>(false);
  const [connectedUser, setConnectedUser] = useState(null);

  const [message, setMessage] = useState<any>(null);
  const socketRef = useRef<WebSocket | null>(null);
  const userCTX = useUser();
  const messageCTX = useMessages();

  const SendToGroup = async (message: string, user: UserType) => {
    try {
      // Effectuez ici votre requête Swagger avec les paramètres fournis
      // Exemple d'utilisation de fetch :

      const params = {
        IdGroup: user.selectedHotel?.IdHotel.toString(),
        Message: message,
        SenderId: userCTX.authenticationInfos.user?.Id,
      };
      const urlToPass = 'SendToGroup';
      const response = await axios.post('/Socket/' + urlToPass, params);
      if (response.status !== 200) {
        throw new Error('Erreur : ');
      }
      const data: MedialogResponse = await response.data;
      return data;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const connectGroup = async (IdHotel: number) => {
    try {
      const params = { GroupId: IdHotel.toString(), UserID: userCTX.authenticationInfos.user?.Id };

      const response = await axios.post('/Socket/AddUserToGroup', params);
      if (response.status !== 200) {
        setIsInGroup(false);
        setIsConnected(false);
      } else {
        setIsInGroup(true);
      }

      const data: string = (await response.data) as string;

      return data;
    } catch (error) {
      getLogger('web').error(error);
    }
  };

  const leaveGroup = async (IdHotel: number) => {
    const response = await axios.post(
      '/Socket/RemoveUserFromGroup?UserID=' + userCTX.authenticationInfos.connectionID + '&GroupId=' + IdHotel,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    if (response.status !== 200) {
      setIsInGroup(false);
      setIsConnected(false);
    } else {
      setIsInGroup(true);
    }

    const data: string = (await response.data) as string;

    return data;
  };

  useEffect(() => {
    const connect = () => {
      const ws = new WebSocket(
        // 'wss://localhost:7001/ws/connect?userId=' +
        config.WS_URL +
          userCTX.authenticationInfos.user?.Id +
          '&connectionId=' +
          userCTX.authenticationInfos.connectionID +
          '&userName=' +
          userCTX.authenticationInfos.user?.FirstnameLastname,
      );
      ws.onopen = () => {
        setIsConnected(true);
      };
      ws.onclose = async () => {
        setIsConnected(false);
        setIsInGroup(false);
        //  await userCTX.getWebSocketUrl();
        setTimeout(connect, 1000); // Reconnect after 1 second
      };
      ws.onmessage = (event) => {
        messageCTX.updateMessages(JSON.parse(event.data));

        setMessage(event.data);
        setData(event.data);
      };
      socketRef.current = ws;
    };

    if (userCTX.authenticationInfos.connectionID && userCTX.authenticationInfos.user?.Id) {
      connect();
    }
    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userCTX.authenticationInfos.connectionID, userCTX.authenticationInfos.user?.Id]);

  useEffect(() => {
    if (isConnected && userCTX.authenticationInfos.selectedHotel) {
      connectGroup(userCTX.authenticationInfos.selectedHotel.IdHotel);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConnected, userCTX.authenticationInfos.selectedHotel]);

  const getConnectedUser = async () => {
    const response = await axios.get('/Socket/GetConnectedUser', {
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.status !== 200) {
      throw new Error('Erreur : ');
    }

    const data = await response.data;
    setConnectedUser(data);
    return data;
  };

  return (
    <WsContext.Provider
      value={{
        data,
        isConnected,
        message,
        connectGroup,
        isInGroup,
        SendToGroup,
        connectedUser,
        getConnectedUser,
        setConnectedUser,
      }}
    >
      {props.children}
    </WsContext.Provider>
  );
};

// Create a custom hook to access the params
export function useWs(): WsContextState {
  const wsContext = useContext(WsContext);
  if (wsContext === undefined) {
    throw new Error('useWs must be used within a WsProvider');
  }
  return wsContext;
}
