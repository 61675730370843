import { useEffect, useState } from 'react';

import { useAnalytics } from '@app/modules/Analytics/AnalyticsContext';
import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { storage } from '@app/store/localstorage/localStorage';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import { Button } from 'primereact/button';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CalendarSelector from '../calendar/CalendarSelector';
import { useMediaQuery } from '@app/utils/useMediaQuery';

const RepartitionFilters: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const [isLocaleSet, setIslocaleSet] = useState(false);
  const filtersCTX = useFilters();
  const repartitionFilters = filtersCTX.filters.repartitionFilters;
  const userCTX = useUser();
  const analyticsCtx = useAnalytics();
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const authData = userCTX.authenticationInfos;
  const hotelList = userCTX.authenticationInfos.listHotel;
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);

  const [t] = useTranslation();

  const AnalyticsData = [
    { name: t('smallbox.label.TO'), code: 0 },

    { name: t('smallbox.label.PM'), code: 1 },

    { name: t('smallbox.label.RevPar'), code: 2 },

    { name: t('smallbox.label.RoomRevenue'), code: 3 },

    { name: t('smallbox.label.GlobalRevenue'), code: 4 },

    { name: t('smallbox.label.ALOS'), code: 5 },
    { name: t('prevision.label.PMH'), code: 6 },
    { name: t('header.label.occupancy'), code: 7 },
  ];
  const AggregationTypeList = [
    // { name: t('labels.day'), code: 0 },
    { name: t('labels.livingCountry'), code: 1 },
    { name: t('prevision.label.Segment'), code: 2 },
    { name: t('prevision.label.Origine'), code: 3 },
    { name: t('prevision.label.rooms'), code: 4 },
    { name: t('labels.roomtype'), code: 5 },
    { name: t('labels.accomodation'), code: 6 },
    { name: t('labels.rateCode'), code: 7 },
    { name: t('prevision.label.Distributeur'), code: 8 },
    // { name: t('labels.month'), code: 9 },
    // { name: t('labels.week'), code: 10 },
    // { name: t('labels.weekday'), code: 11 },
    { name: t('labels.salesCategory'), code: 12 },
    { name: t('labels.salesAnalyticCategory'), code: 13 },
  ];

  const DataGroupedBy = [
    { name: t('labels.day'), code: 0 },
    { name: t('labels.week'), code: 1 },
    { name: t('labels.month'), code: 2 },
    { name: t('labels.none'), code: 3 },
  ];
  useEffect(() => {
    if (isTech && selectedHotel) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        previsionFilters: {
          ...prevState.previsionFilters,
          needReload: true,
        },
      }));
    }
    if (hotelList?.length === 1 && repartitionFilters !== null) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        repartitionFilters: {
          ...prevState.repartitionFilters,
          ListHotels: hotelList,
          needReload: true,
        },
      }));
    } else {
      if (selectedHotel) {
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          repartitionFilters: {
            ...prevState.repartitionFilters,
            ListHotels: [selectedHotel as HotelRight],
            needReload: true,
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelList]);
  const isPortrait = useMediaQuery('(orientation: portrait)');

  const customHeader = (options: MultiSelectPanelHeaderTemplateEvent) => {
    const onHeaderCheckboxChange = (e: any) => {
      // e.checked donne l'état actuel de la case à cocher
      // Utilisez cette valeur pour gérer le changement
      options.onChange(e.checked);
    };
    return (
      <div className="p-multiselect-header" style={{ alignItems: 'center', marginTop: '5px' }}>
        <div className="p-checkbox p-component">
          <input
            style={{
              border: '2px solid #ced4da',
              background: '#ffffff',
              width: '20px',
              height: '20px',
              color: '#495057',
              borderRadius: '4px',

              transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
            }}
            type="checkbox"
            role="checkbox"
            checked={options.checked}
            onChange={(e) => onHeaderCheckboxChange({ originalEvent: e, checked: e.target.checked })}
          />
        </div>
        <span style={{ marginLeft: '5px' }}>Tous</span>
      </div>
    );
  };

  useEffect(() => {
    setIslocaleSet(false);
    if (authData.language) {
      setIslocaleSet(true);
    }
  }, [authData.language]);

  return (
    <div
      style={{
        display: !isMobile ? 'flex' : '',
        flexDirection: isMobileOnly ? (orientation === 'portrait' ? 'column' : 'row') : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLocaleSet && repartitionFilters && isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <CalendarSelector
              numberOfMonthtoDisplay={isMobile ? (isPortrait ? 1 : 2) : 2}
              data={{ DateStart: repartitionFilters.DateStart, DateEnd: repartitionFilters.DateEnd }}
              updateParentFilter={(start: number | string | Date, end: number | string | Date) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  repartitionFilters: {
                    ...prevState.repartitionFilters,
                    DateStart: start,
                    DateEnd: end,
                    hasBeenModified: true,
                  },
                }));
              }}
              shouldClose={repartitionFilters.needReload}
              width="160px"
            ></CalendarSelector>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '5px',
              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {!isTech && (
              <MultiSelect
                disabled={hotelList?.length === 1}
                value={repartitionFilters.ListHotels}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    repartitionFilters: {
                      ...prevState.repartitionFilters,
                      ListHotels: e.value,
                      hasBeenModified: true,
                    },
                  }));
                }}
                panelHeaderTemplate={customHeader}
                options={
                  hotelList?.sort((a, b) => {
                    if (a.Name < b.Name) {
                      return -1;
                    }
                    if (a.Name > b.Name) {
                      return 1;
                    }
                    return 0;
                  }) ?? []
                }
                optionLabel="Name"
                placeholder={t('label.placeholder.choice') ?? ''}
                style={{ width: '150px' }}
              />
            )}
            {isTech && (
              <Dropdown
                value={selectedHotel}
                onChange={async (e) => {
                  userCTX.setUser((prevState) => ({
                    ...prevState,
                    selectedHotel: e.value ?? '',
                  }));
                  storage.setParam('selectedHotel', JSON.stringify(e.value));
                }}
                options={hotelList as HotelData[]}
                optionLabel="Name"
                filter
                placeholder={t('label.placeholder.choice') ?? ''}
                className="w-full md:w-14rem"
                style={{ whiteSpace: 'nowrap', maxWidth: '150px' }}
              />
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '5px',
              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Dropdown
              value={repartitionFilters.Category}
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  repartitionFilters: {
                    ...prevState.repartitionFilters,
                    Category: e.value,
                    hasBeenModified: true,
                  },
                }));
              }}
              options={AnalyticsData}
              optionLabel="name"
              placeholder={t('labels.analyticsData') ?? ''}
              style={{ width: '150px' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '5px',
              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Checkbox
              disabled={
                repartitionFilters.Category?.code === 0 ? false : repartitionFilters.Category?.code === 2 ? false : true
              }
              checked={repartitionFilters.KpiAreProrata}
              id="KpiAreProrata"
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  repartitionFilters: {
                    ...prevState.repartitionFilters,
                    KpiAreProrata: e.checked ?? false,
                    hasBeenModified: true,
                  },
                }));
              }}
              placeholder={t('labels.groupby') ?? ''}
              style={{ marginRight: '5px', marginLeft: '5px' }}
            />
            <label
              style={{
                opacity:
                  repartitionFilters.Category?.code === 0 ? 1 : repartitionFilters.Category?.code === 2 ? 1 : 0.5,
                margin: 0,
              }}
              htmlFor="KpiAreProrata"
              title={t('labels.KpiAreProrata')}
            >
              {t('labels.ShortKpiAreProrata')}
            </label>
          </div>

          <Dropdown
            id="RepartitionType"
            value={repartitionFilters.AggregationType}
            onChange={(e) => {
              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                repartitionFilters: {
                  ...prevState.repartitionFilters,
                  AggregationType: e.target.value,
                  hasBeenModified: true,
                },
              }));
            }}
            options={AggregationTypeList}
            optionLabel="name"
            placeholder={t('labels.AggregationType') ?? ''}
            style={{ marginRight: '5px', marginLeft: '5px', width: '150px' }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '5px',
              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Dropdown
                value={DataGroupedBy.find((e) => e.code === repartitionFilters.GroupBy)}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    repartitionFilters: {
                      ...prevState.repartitionFilters,
                      GroupBy: e.value.code,
                      hasBeenModified: true,
                    },
                  }));
                  analyticsCtx.setAnalyticsRepartition(null);
                }}
                options={DataGroupedBy}
                optionLabel="name"
                placeholder={t('labels.groupby') ?? ''}
                style={{ width: '100px' }}
              />
            </div>
            <Button
              id="repartitionButton"
              disabled={isTech ? false : repartitionFilters.ListHotels.length <= 0}
              style={{
                marginRight: '5px',
                marginLeft: '5px',
                borderRadius: '4px',
                backgroundColor: filtersCTX.filters.repartitionFilters.hasBeenModified
                  ? colors.colorSuccessMain
                  : darkMode
                    ? 'var(--color-primary-light)'
                    : 'var(--color-primary-main)',

                textAlign: 'center',
                justifyContent: 'center',
              }}
              icon="pi pi-refresh"
              onClick={() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  repartitionFilters: {
                    ...prevState.repartitionFilters,
                    needReload: true,
                    hasBeenModified: false,
                  },
                }));
              }}
            ></Button>
          </div>
        </div>
      )}

      {isLocaleSet && repartitionFilters && !isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',

            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <CalendarSelector
              numberOfMonthtoDisplay={2}
              data={{ DateStart: repartitionFilters.DateStart, DateEnd: repartitionFilters.DateEnd }}
              updateParentFilter={(start: number | string | Date, end: number | string | Date) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  repartitionFilters: {
                    ...prevState.repartitionFilters,
                    DateStart: start,
                    DateEnd: end,
                    hasBeenModified: true,
                  },
                }));
              }}
              shouldClose={repartitionFilters.needReload}
              width="160px"
            ></CalendarSelector>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {!isTech && (
              <MultiSelect
                disabled={hotelList?.length === 1}
                value={repartitionFilters.ListHotels}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    repartitionFilters: {
                      ...prevState.repartitionFilters,
                      ListHotels: e.value,
                      hasBeenModified: true,
                    },
                  }));
                }}
                panelHeaderTemplate={customHeader}
                options={
                  hotelList?.sort((a, b) => {
                    if (a.Name < b.Name) {
                      return -1;
                    }
                    if (a.Name > b.Name) {
                      return 1;
                    }
                    return 0;
                  }) ?? []
                }
                optionLabel="Name"
                placeholder={t('label.placeholder.choice') ?? ''}
                style={{ marginRight: '5px', marginLeft: '5px', width: '150px' }}
              />
            )}
            {isTech && (
              <Dropdown
                value={selectedHotel}
                onChange={async (e) => {
                  userCTX.setUser((prevState) => ({
                    ...prevState,
                    selectedHotel: e.value ?? '',
                  }));
                  storage.setParam('selectedHotel', JSON.stringify(e.value));
                }}
                options={hotelList as HotelData[]}
                optionLabel="Name"
                filter
                placeholder={t('label.placeholder.choice') ?? ''}
                className="w-full md:w-14rem"
                style={{ whiteSpace: 'nowrap', maxWidth: '150px', marginRight: '5px', marginLeft: '5px' }}
              />
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Dropdown
              value={repartitionFilters.Category}
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  repartitionFilters: {
                    ...prevState.repartitionFilters,
                    Category: e.value,
                    hasBeenModified: true,
                  },
                }));
              }}
              options={AnalyticsData}
              optionLabel="name"
              placeholder={t('labels.analyticsData') ?? ''}
              style={{ marginRight: '5px', marginLeft: '5px', width: '150px' }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Checkbox
              disabled={
                repartitionFilters.Category?.code === 0 ? false : repartitionFilters.Category?.code === 2 ? false : true
              }
              checked={repartitionFilters.KpiAreProrata}
              id="KpiAreProrata"
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  repartitionFilters: {
                    ...prevState.repartitionFilters,
                    KpiAreProrata: e.checked ?? false,
                    hasBeenModified: true,
                  },
                }));
              }}
              placeholder={t('labels.groupby') ?? ''}
              style={{ marginRight: '5px', marginLeft: '5px' }}
            />
            <label
              style={{
                opacity:
                  repartitionFilters.Category?.code === 0 ? 1 : repartitionFilters.Category?.code === 2 ? 1 : 0.5,
                margin: 0,
              }}
              htmlFor="KpiAreProrata"
              title={t('labels.KpiAreProrata')}
            >
              {t('labels.ShortKpiAreProrata')}
            </label>
          </div>

          <Dropdown
            id="RepartitionType"
            value={repartitionFilters.AggregationType}
            onChange={(e) => {
              filtersCTX.setFilters((prevState) => ({
                ...prevState,
                repartitionFilters: {
                  ...prevState.repartitionFilters,
                  AggregationType: e.target.value,
                  hasBeenModified: true,
                },
              }));
            }}
            options={AggregationTypeList}
            optionLabel="name"
            placeholder={t('labels.AggregationType') ?? ''}
            style={{ marginRight: '5px', marginLeft: '5px', width: '150px' }}
          />
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',

                flexWrap: 'wrap',
                alignContent: 'center',
                alignItems: 'center',
                flexDirection: 'row',
              }}
            >
              <Dropdown
                value={DataGroupedBy.find((e) => e.code === repartitionFilters.GroupBy)}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    repartitionFilters: {
                      ...prevState.repartitionFilters,
                      GroupBy: e.value.code,
                      hasBeenModified: true,
                    },
                  }));
                  analyticsCtx.setAnalyticsRepartition(null);
                }}
                options={DataGroupedBy}
                optionLabel="name"
                placeholder={t('labels.groupby') ?? ''}
                style={{ marginRight: '5px', marginLeft: '5px', width: '100px' }}
              />
            </div>
            <Button
              id="repartitionButton"
              disabled={isTech ? false : repartitionFilters.ListHotels.length <= 0}
              style={{
                marginRight: '5px',
                marginLeft: '5px',
                borderRadius: '4px',
                backgroundColor: filtersCTX.filters.repartitionFilters.hasBeenModified
                  ? colors.colorSuccessMain
                  : darkMode
                    ? 'var(--color-primary-light)'
                    : 'var(--color-primary-main)',

                textAlign: 'center',
                justifyContent: 'center',
              }}
              icon="pi pi-refresh"
              onClick={() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  repartitionFilters: {
                    ...prevState.repartitionFilters,
                    needReload: true,
                    hasBeenModified: false,
                  },
                }));
              }}
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default RepartitionFilters;
