import { ReactNode } from 'react';

import CountryDropdown from '@app/components/Common/flags/CountryDropdown';
import PickupSelector from '@app/components/Common/popup/pickupSelector';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import { useKardex } from '@app/modules/kardex/KardexContext';
import { useSubForm } from '@app/modules/SubFormContext';
import { useTabView } from '@app/modules/Tabview/TabViewContext';
import { useUser } from '@app/modules/user/UserContext';
import { DisplayColorsEntity } from '@app/types/dataFactory';
import { SingleKardexType } from '@app/types/types';
import { shortOnlyMonthDateToLocaleString } from '@app/utils/dates';
import { isEmpty } from 'lodash';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Calendar } from 'primereact/calendar';
import { Card } from 'primereact/card';
import { Checkbox } from 'primereact/checkbox';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { TabPanel, TabView } from 'primereact/tabview';

import CompteAvoirGrid from './CompteAvoirGrid';
import KardexHistory from './KardexHistory';
import KardexPicture from './KardexPicture';
import KardexStay from './KardexStay';
import { isMobile } from 'react-device-detect';

interface props {
  data?: SingleKardexType;

  title: ReactNode;
  showTitle: boolean;
  showOnlyKardex?: boolean;
  showOnlyMemo?: boolean;
}

const Kardex: React.FC<props> = (props) => {
  const kardexCTX = useKardex();
  const dataFactoryCTX = useDataFactory();
  const subFormCTX = useSubForm();
  const userCTX = useUser();
  const tabViewCTX = useTabView();
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;

  const openKardex = async (IdKardex: string) => {
    kardexCTX.getKardekById(selectedHotel?.IdHotel as number, IdKardex).then((t) => {
      const id = tabViewCTX.currentID;
      const newTabView = {
        id: id, // ou utilisez un ID unique
        component: Kardex, // Remplacez par le composant que vous souhaitez afficher
        data: t, // Les données que vous souhaitez transmettre au composant
        componentType: 'Kardex',
        actions: {
          hideTabView: () => {
            tabViewCTX.hideTabView(id);
            kardexCTX.setCurrentKardex(null);
            kardexCTX.setStayFilters(null);
          },
        },
        draggable: false, // Définissez-le en fonction de vos besoins
        fullWidth: true, // Définissez-le en fonction de vos besoins
        fullHeight: true, // Définissez-le en fonction de vos besoins
        translatedTitle: t?.Nom,
      };

      // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
      tabViewCTX.setTabView((prevSubForm) => ({
        ...prevSubForm,
        listOfTabsDisplayed: [...prevSubForm.listOfTabsDisplayed, newTabView],
      }));

      tabViewCTX.setCurrentID(id + 1);
      tabViewCTX.setActiveTab(id);
    });
  };

  const openSegment = () => {
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: PickupSelector, // Remplacez par le composant que vous souhaitez afficher
      data: { resources: dataFactoryCTX?.DataDictionnary?.Segments },
      actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
      draggable: true, // Définissez-le en fonction de vos besoins
      fullWidth: false, // Définissez-le en fonction de vos besoins
      fullHeight: false, // Définissez-le en fonction de vos besoins
      width: '300px',
      height: '500px',
      x: 'calc((100vw - 300px)/2)',
      y: '100px',
      translatedTitle: 'Segments',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };
  const openOrigine = () => {
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: PickupSelector, // Remplacez par le composant que vous souhaitez afficher
      data: { resources: dataFactoryCTX?.DataDictionnary?.Origines },
      actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
      draggable: true, // Définissez-le en fonction de vos besoins
      fullWidth: false, // Définissez-le en fonction de vos besoins
      fullHeight: false, // Définissez-le en fonction de vos besoins
      width: '300px',
      height: '500px',
      x: 'calc((100vw - 300px)/2)',
      y: '100px',
      translatedTitle: 'Origines',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  const openHistory = () => {
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: KardexHistory, // Remplacez par le composant que vous souhaitez afficher
      actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
      draggable: true, // Définissez-le en fonction de vos besoins
      fullWidth: false, // Définissez-le en fonction de vos besoins
      fullHeight: false, // Définissez-le en fonction de vos besoins
      width: '800px',
      height: '600px',
      x: 'calc((100vw - 600px)/2)',
      y: '100px',
      translatedTitle: 'Historiques des modifications',
      data: {},
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  const openPicture = () => {
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: KardexPicture, // Remplacez par le composant que vous souhaitez afficher
      actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
      draggable: true, // Définissez-le en fonction de vos besoins
      fullWidth: false, // Définissez-le en fonction de vos besoins
      fullHeight: false, // Définissez-le en fonction de vos besoins
      width: '250px',
      height: '380px',
      x: 'calc((100vw - 600px)/2)',
      y: '100px',
      translatedTitle: kardexCTX.CurrentKardex?.Prenom + ' ' + kardexCTX.CurrentKardex?.Nom,
      data: {},
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };

  const selectedColorOption = (option: DisplayColorsEntity) => {
    return (
      <div
        style={{
          width: '25px',
          height: '25px',
          backgroundColor: dataFactoryCTX?.DataDictionnary?.DisplayColors?.find((e) => e.Id === props?.data?.IdColor)
            ?.Value,
        }}
      >
        {option?.Id}
      </div>
    );
  };

  const colorOption = (option: DisplayColorsEntity) => {
    return (
      <div
        style={{
          width: '25px',
          height: '25px',
          backgroundColor: option?.Value,
        }}
      ></div>
    );
  };
  return (
    <div style={{ display: 'flex', flexDirection: 'row' }}>
      {!props.showOnlyMemo && (
        <div
          style={{
            width: (props.showOnlyKardex ?? (false || props.showOnlyMemo) ?? false) ? '100%' : '50%',
            padding: '0px',
          }}
        >
          <>
            <Card style={{ margin: '10px', backgroundColor: '#ededed' }}>
              <div style={{ display: 'flex', alignItems: 'center', padding: '5px' }}>
                <Button
                  icon="fa-solid fa-clock-rotate-left"
                  onClick={() => openHistory()}
                  style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
                ></Button>
                <Button
                  icon={props.data?.BlackList ? 'fa-solid fa-shield-virus' : 'fa-solid fa-shield'}
                  style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
                ></Button>
                <Button
                  icon={!props.data?.Protected ? 'fa-solid fa-lock-open' : 'fa-solid fa-lock'}
                  style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
                ></Button>
                <Button
                  icon="fa-solid fa-circle-info"
                  style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
                ></Button>
                <span>ID : </span>
                <span> {props.data?.Id}</span>
                {props.data?.HasPictureID && (
                  <Button
                    style={{
                      width: '24px',
                      height: '24px',
                      marginLeft: '5px',
                      marginRight: '5px',
                      borderRadius: '7.5px',
                    }}
                    icon="fa-regular fa-image"
                    onClick={openPicture}
                  ></Button>
                )}
              </div>
              <div style={{ justifyContent: 'space-between', display: 'flex', padding: '5px' }}>
                <InputText placeholder="Nom" defaultValue={props.data?.Nom} style={{ marginRight: '5px' }}></InputText>
                {dataFactoryCTX?.DataDictionnary?.Titles?.find((e) => e.Id === props?.data?.IdTitre)
                  ?.FirstNameActivated && (
                  <InputText
                    placeholder="Prénom"
                    defaultValue={props.data?.Prenom}
                    style={{ marginRight: '5px' }}
                  ></InputText>
                )}
                <Dropdown
                  value={dataFactoryCTX?.DataDictionnary?.Titles?.find((e) => e.Id === props?.data?.IdTitre)?.Id}
                  placeholder="Type de Kardex"
                  style={{ marginRight: '5px' }}
                  options={dataFactoryCTX?.DataDictionnary?.Titles ?? []}
                  optionLabel="Libelle"
                  optionValue="Id"
                ></Dropdown>
                <CountryDropdown
                  searchMode="Libelle"
                  data={dataFactoryCTX?.DataDictionnary?.Countries?.find((e) => e.Id === props?.data?.IdNationality)}
                ></CountryDropdown>
                <Dropdown
                  value={
                    dataFactoryCTX?.DataDictionnary?.CustomerCultures?.find(
                      (e) => e.Id.toString() === props?.data?.IdLangue.toString(),
                    )?.Id
                  }
                  placeholder="Langue"
                  optionLabel="Libelle"
                  optionValue="Id"
                  options={dataFactoryCTX?.DataDictionnary?.CustomerCultures ?? []}
                  style={{ marginRight: '5px' }}
                ></Dropdown>
                {
                  <Dropdown
                    value={dataFactoryCTX?.DataDictionnary?.DisplayColors?.find((e) => e.Id === props?.data?.IdColor)}
                    itemTemplate={colorOption}
                    valueTemplate={selectedColorOption}
                    optionValue="Id"
                    options={dataFactoryCTX?.DataDictionnary?.DisplayColors ?? []}
                    placeholder="Couleur"
                    style={{}}
                  ></Dropdown>
                }
              </div>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <Button
                  icon="fa-solid fa-circle-info"
                  style={{ width: '24px', height: '24px', marginRight: '5px', borderRadius: '7.5px' }}
                  onClick={() =>
                    window.open(
                      // eslint-disable-next-line max-len
                      'https://medialog.atlassian.net/wiki/spaces/GU/pages/662601849/Address+Finder+La+saisie+intuitive+automatique+d+adresses+postales',
                    )
                  }
                ></Button>
                <InputText
                  defaultValue={props.data?.Address?.Address}
                  placeholder="Adresse"
                  style={{ width: 'calc(100% - 30px)' }}
                ></InputText>
              </div>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <InputText
                  defaultValue={props.data?.Address?.ZipCode}
                  style={{ width: '15%', marginRight: '5px' }}
                  placeholder="Code postal"
                ></InputText>
                <InputText
                  defaultValue={props.data?.Address?.City}
                  style={{ width: '70%', marginRight: '5px' }}
                  placeholder="Ville"
                ></InputText>

                <CountryDropdown
                  showLibelle
                  searchMode="Libelle"
                  placeholder="Pays"
                  data={dataFactoryCTX?.DataDictionnary?.Countries?.find(
                    (e) => e.Id === props?.data?.Address?.IdCountry,
                  )}
                ></CountryDropdown>
              </div>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginRight: '20px',
                  }}
                >
                  <span>
                    <i className="fa-solid fa-mobile-screen-button"></i>
                  </span>
                  <CountryDropdown
                    searchMode="PhoneCode"
                    data={dataFactoryCTX?.DataDictionnary?.Countries?.find(
                      (e) => e.Id === props.data?.MobilePhone?.CountryCode,
                    )}
                  ></CountryDropdown>

                  <InputText placeholder="Telephone" defaultValue={props.data?.MobilePhone?.Number}></InputText>
                </div>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>
                    <i className="fa-solid fa-house"></i>
                  </span>
                  <CountryDropdown
                    searchMode="PhoneCode"
                    data={dataFactoryCTX?.DataDictionnary?.Countries?.find(
                      (e) => e.Id === props.data?.HomePhone?.CountryCode,
                    )}
                  ></CountryDropdown>
                  <InputText placeholder="Telephone" defaultValue={props.data?.HomePhone?.Number}></InputText>
                </div>
              </div>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginRight: '20px',
                  }}
                >
                  <span>
                    <i className="fa-regular fa-building"></i>
                  </span>
                  <CountryDropdown
                    searchMode="PhoneCode"
                    data={dataFactoryCTX?.DataDictionnary?.Countries?.find(
                      (e) => e.Id === props.data?.BusinessPhone?.CountryCode,
                    )}
                  ></CountryDropdown>

                  <InputText placeholder="Telephone" defaultValue={props.data?.BusinessPhone?.Number}></InputText>
                </div>
                <div
                  style={{
                    width: '50%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  <span>
                    <i className="fa-solid fa-fax"></i>
                  </span>
                  <CountryDropdown
                    searchMode="PhoneCode"
                    data={dataFactoryCTX?.DataDictionnary?.Countries?.find(
                      (e) => e.Id === props?.data?.Fax?.CountryCode,
                    )}
                  ></CountryDropdown>

                  <InputText placeholder="Telephone" defaultValue={props.data?.Fax?.Number}></InputText>
                </div>
              </div>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <span>
                  <i className="fa-solid fa-at"></i>
                </span>

                <InputText
                  placeholder="Email"
                  defaultValue={props.data?.Email}
                  style={{ width: 'calc(100% - 32px)' }}
                ></InputText>
              </div>
            </Card>
            <Card style={{ margin: '10px', backgroundColor: '#ededed' }}>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <Button
                  style={{
                    marginRight: '5px',
                    fontSize: props?.data?.CreditCard ? '12px' : '16px',
                    width: '200px',
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {props?.data?.CreditCard &&
                    props?.data?.CreditCard?.CardTypeLabel +
                      ' ' +
                      shortOnlyMonthDateToLocaleString(
                        moment(props.data?.CreditCard?.ExpiryDate).toDate(),
                        userCTX?.authenticationInfos?.user?.Culture ?? 'fr',
                      )}
                  {!props?.data?.CreditCard && 'Carte Bancaire'}
                </Button>
                {dataFactoryCTX?.DataDictionnary?.Titles?.find((e) => e.Id === props?.data?.IdTitre)?.IsCompany && (
                  <InputText placeholder="Contact"></InputText>
                )}
                {dataFactoryCTX?.DataDictionnary?.Titles?.find((e) => e.Id === props?.data?.IdTitre)?.IsCompany ===
                  false && (
                  <>
                    <Button style={{ marginRight: '5px', whiteSpace: 'nowrap' }}>
                      {props.data?.Passport && props.data?.Passport?.Numero}
                      {!props.data?.Passport && "Document d'identité"}
                    </Button>
                    <InputText
                      style={{ marginRight: '5px', width: '20%' }}
                      placeholder="Profession"
                      value={props?.data?.Job}
                    ></InputText>
                    <Calendar
                      touchUI={isMobile}
                      style={{ marginRight: '5px', width: '20%' }}
                      placeholder="Date de Naissance"
                      value={moment(props?.data?.BirthDate).toDate()}
                    ></Calendar>
                    <InputText
                      style={{ marginRight: '5px', width: '20%' }}
                      placeholder="Ville de naissance"
                      value={props?.data?.BirthCity}
                    ></InputText>

                    <CountryDropdown
                      showLibelle
                      searchMode="Libelle"
                      placeholder="Pays"
                      data={dataFactoryCTX?.DataDictionnary?.Countries?.find((e) => e.Id === props.data?.IdNationality)}
                    ></CountryDropdown>
                  </>
                )}
              </div>
              {dataFactoryCTX?.DataDictionnary?.Titles?.find((e) => e.Id === props?.data?.IdTitre)?.IsCompany ===
                true && (
                <>
                  <div
                    style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}
                  >
                    <InputText placeholder="RCS" defaultValue={props.data?.Rcs}></InputText>
                    <InputText placeholder="Numéro Siret" defaultValue={props.data?.Siret}></InputText>
                    <InputText placeholder="TVA" defaultValue={props.data?.TvaIntraComm}></InputText>
                  </div>
                  <div
                    style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}
                  >
                    <InputText placeholder="Code IATAN" defaultValue={props.data?.IataCode}></InputText>
                    <InputText placeholder="Site Internet" defaultValue={props.data?.WebSite}></InputText>
                  </div>
                </>
              )}

              {dataFactoryCTX?.DataDictionnary?.Titles?.find((e) => e.Id === props?.data?.IdTitre)?.IsCompany ===
                false && (
                <>
                  <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', padding: '5px' }}>
                    <i className="fa-solid fa-user" style={{ marginRight: '10px' }}></i>
                    <span style={{ marginRight: '10px' }}>Informations compte débiteur</span>
                  </div>
                  <div
                    style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}
                  >
                    <span>Solde maximum autorisé : </span>{' '}
                    <InputText
                      style={{ width: '7%' }}
                      type="number"
                      placeholder="0"
                      defaultValue={props.data?.DebtorProps.BalanceAuthorized}
                    ></InputText>
                    <span>Echéance débiteur maximum (en jours) : </span>{' '}
                    <InputText
                      style={{ width: '7%' }}
                      type="number"
                      placeholder="0"
                      value={props.data?.DebtorProps.ReminderNbDays.toString()}
                    ></InputText>
                    <span>Compter à partir de la fin de mois </span>{' '}
                    <Checkbox checked value={props.data?.DebtorProps.ReminderFromEndOfMonth}></Checkbox>
                  </div>
                </>
              )}
            </Card>
            <Card style={{ margin: '10px', backgroundColor: '#ededed' }}>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <Button onClick={openSegment}>
                  {props.data?.IdSegment.map(
                    (e, i) =>
                      dataFactoryCTX?.DataDictionnary?.Segments?.find((t) => t.Id === e)?.Libelle +
                      (((props?.data?.IdSegment && i < props?.data?.IdSegment?.length - 1) ?? 0) ? ' | ' : ''),
                  )}
                </Button>
                <Button onClick={openOrigine}>
                  {props.data?.IdOrigine.map(
                    (e, i) =>
                      dataFactoryCTX?.DataDictionnary?.Origines?.find((t) => t.Id === e)?.Libelle +
                      (((props?.data?.IdOrigine && i < props?.data?.IdOrigine?.length - 1) ?? 0) ? ' | ' : ''),
                  )}
                </Button>

                <Dropdown
                  hidden
                  value={
                    dataFactoryCTX?.DataDictionnary?.Countries?.find(
                      (e) => e.Id.toString() === props?.data?.IdAccountType,
                    )?.Id
                  }
                  optionLabel="Libelle"
                  optionValue="Id"
                  options={dataFactoryCTX?.DataDictionnary?.KardexAccountTypes ?? []}
                  style={{ width: '20%', marginRight: '5px' }}
                  placeholder="Type de compte"
                ></Dropdown>
                <Dropdown
                  value={
                    dataFactoryCTX?.DataDictionnary?.KardexCategories?.find(
                      (e) => e.Id.toString() === props?.data?.IdKardexCategory?.toString(),
                    )?.Id
                  }
                  optionLabel="Libelle"
                  optionValue="Id"
                  options={dataFactoryCTX?.DataDictionnary?.KardexCategories ?? []}
                  style={{ width: '20%', marginRight: '5px' }}
                  placeholder="Catégorie"
                ></Dropdown>
                <Dropdown
                  value={
                    dataFactoryCTX?.DataDictionnary?.BookingStayGoal?.find((e) => e.Id === props?.data?.StayGoal)?.Id
                  }
                  optionLabel="Libelle"
                  optionValue="Id"
                  options={dataFactoryCTX?.DataDictionnary?.BookingStayGoal ?? []}
                  style={{ width: '20%' }}
                  placeholder="But des séjours"
                ></Dropdown>
              </div>
            </Card>
            <Card style={{ margin: '10px', backgroundColor: '#ededed' }}>
              <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <div style={{ width: '50%' }}>
                  {props?.data?.IdCodesClient.map((elt, i) => {
                    return (
                      <>
                        <Dropdown
                          key={'market' + i}
                          value={dataFactoryCTX?.DataDictionnary?.CodesClient?.find((e) => e.Id.toString() === elt)?.Id}
                          optionLabel="Libelle"
                          optionValue="Id"
                          options={dataFactoryCTX?.DataDictionnary?.CodesClient ?? []}
                          style={{ width: 'calc(100% - 100px)', marginRight: '5px', marginBottom: '10px' }}
                          placeholder="Ajouter un code marketing"
                        ></Dropdown>
                        <Button
                          style={{ marginLeft: '10px', marginRight: '10px', marginBottom: '10px' }}
                          icon="fa-solid fa-trash"
                        ></Button>
                      </>
                    );
                  })}
                  <Button style={{}} icon="fa-solid fa-plus"></Button>
                </div>
                <div style={{ width: '50%' }}>
                  <Dropdown
                    style={{ width: '100%' }}
                    value={
                      dataFactoryCTX?.DataDictionnary?.SaleGuaranteeType?.find(
                        (e) => e?.Id?.toString() === props?.data?.BookingGuarantee?.Type?.toString(),
                      )?.Id
                    }
                    optionLabel="Libelle"
                    optionValue="Id"
                    options={dataFactoryCTX?.DataDictionnary?.SaleGuaranteeType ?? []}
                    placeholder="Garantie de reservation"
                  ></Dropdown>
                </div>
              </div>
            </Card>
            <Card style={{ margin: '10px', backgroundColor: '#ededed' }}>
              <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <span>Choix du kardex Payeur :</span>
                {props.data?.PayeurName && (
                  <>
                    <Button
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      onClick={() => openKardex(props.data?.PayeurId ?? '')}
                    >
                      {props.data?.PayeurName}
                    </Button>
                    <Button style={{ marginLeft: '10px', marginRight: '10px' }} icon="fa-solid fa-trash"></Button>
                  </>
                )}
                {!props.data?.PayeurName && <Button style={{ marginLeft: '20px' }}>Définir un payeur</Button>}
              </div>{' '}
              <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <span>Parent :</span>
                {props.data?.ParentName && (
                  <>
                    <Button
                      style={{ marginLeft: '10px', marginRight: '10px' }}
                      onClick={() => openKardex(props.data?.ParentId ?? '')}
                    >
                      {props.data?.ParentName}
                    </Button>
                    <Button style={{ marginLeft: '10px', marginRight: '10px' }} icon="fa-solid fa-trash"></Button>
                  </>
                )}
                {!props.data?.ParentName && <Button style={{ marginLeft: '20px' }}>Ajouter un kardex parent</Button>}
              </div>{' '}
              <div style={{ justifyContent: 'flex-start', display: 'flex', alignItems: 'center', padding: '5px' }}>
                <span>Enfant : </span>
                {props?.data?.Children?.map((e, i) => {
                  return (
                    <>
                      <Button
                        style={{ marginLeft: '10px', marginRight: '10px' }}
                        key={'btnKdx' + i}
                        onClick={() => openKardex(e.Id ?? '')}
                      >
                        {e.Libelle}
                      </Button>
                      <Button style={{ marginLeft: '10px', marginRight: '10px' }} icon="fa-solid fa-trash"></Button>
                      {i === (props.data?.Children?.length ?? 0) - 1 && (
                        <Button style={{ marginLeft: '20px' }}>Ajouter un kardex enfant</Button>
                      )}
                    </>
                  );
                })}
                {isEmpty(props.data?.Children) && (
                  <Button style={{ marginLeft: '20px' }}>Ajouter un kardex enfant</Button>
                )}
              </div>
            </Card>
          </>
        </div>
      )}
      {!props.showOnlyKardex && (
        <div
          style={{
            width: (props.showOnlyKardex ?? (false || props.showOnlyMemo) ?? false) ? '100%' : '50%',
            padding: '0px',
            height: 'calc(100vh - 250px)',
          }}
        >
          <Card style={{ margin: '10px', minHeight: '100%' }}>
            <TabView style={{ height: '100%', border: 0 }}>
              <TabPanel header="Note" style={{ height: '100%', border: 0 }}>
                <div dangerouslySetInnerHTML={{ __html: props.data?.Memo ?? '' }}></div>
              </TabPanel>
              <TabPanel style={{ height: '100%', border: 0 }} header="Compte d'avoir">
                <CompteAvoirGrid></CompteAvoirGrid>
              </TabPanel>

              <TabPanel header="Séjours">
                <KardexStay></KardexStay>
              </TabPanel>
            </TabView>
          </Card>
          <Card style={{ margin: '10px' }}>
            <div style={{ justifyContent: 'space-between', display: 'flex', alignItems: 'center', padding: '5px' }}>
              <Button
                title="Valeurs par défaut création de résa."
                icon="fa-solid fa-wrench"
                style={{ marginRight: '5px', borderRadius: '7.5px' }}
              ></Button>
              <Button
                title="Créer une réservation"
                icon="fa-solid fa-wand-sparkles"
                style={{ marginRight: '5px', borderRadius: '7.5px' }}
              ></Button>
              <Button style={{ marginRight: '5px', borderRadius: '7.5px' }}>RGPD</Button>
              <Button icon="fa-solid fa-print" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
              <Button icon="fa-solid fa-file-pdf" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
              <Button icon="fa-solid fa-envelope" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>

              <Button icon="fa-solid fa-percent" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
              <Button icon="fa-solid fa-code-merge" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
              <Button icon="fa-solid fa-trash" style={{ marginRight: '5px', borderRadius: '7.5px' }}></Button>
            </div>
          </Card>
        </div>
      )}
    </div>
  );
};
export default Kardex;
