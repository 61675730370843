import React, { useState } from 'react';

import TodaysClock from '@app/components/Common/popup/todaysClock';
import { useFilters } from '@app/modules/filter/filterContext';
import { useSubForm } from '@app/modules/SubFormContext';
import colors from '@app/styles/colors';
import { Button } from 'primereact/button';
import { useTranslation } from 'react-i18next';
import { useDataFactory } from '@app/modules/DataFactory/DataFactoryContext';
import moment from 'moment-timezone';

interface ResourceFooterProps {
  hideSR: boolean;
  hideNA: boolean;
}

const ResourceFooter: React.FC<ResourceFooterProps> = ({ hideSR, hideNA }) => {
  const [t] = useTranslation();

  const [departure, setShowDepartur] = useState(false);
  const subFormCTX = useSubForm();
  const filtersCTX = useFilters();
  const dataFactoryCTX = useDataFactory();
  const onEventClick = () => {
    const id = subFormCTX.subForm.listOfSubformsDisplayed.length + 1;
    const newSubForm = {
      id: id, // ou utilisez un ID unique
      component: TodaysClock, // Remplacez par le composant que vous souhaitez afficher
      data: null, // Les données que vous souhaitez transmettre au composant
      actions: { hideSubForm: () => subFormCTX.hideSubForm(id) },
      draggable: true, // Définissez-le en fonction de vos besoins
      fullWidth: false, // Définissez-le en fonction de vos besoins
      fullHeight: false, // Définissez-le en fonction de vos besoins
      translatedTitle: 'Alarmes du jour',
      x: 'calc(50% - 200px)',
      y: '20vh',
    };

    // Utilisez setSubForm pour ajouter le nouveau "subform" à la liste
    subFormCTX.setSubForm((prevSubForm) => ({
      ...prevSubForm,
      listOfSubformsDisplayed: [...prevSubForm.listOfSubformsDisplayed, newSubForm],
    }));
  };
  const showDeparture = () => {
    setShowDepartur(!departure);

    filtersCTX.setFilters((prevState) => ({
      ...prevState,
      planningFilters: { ...prevState.planningFilters, showDeparture: !departure },
    }));
  };

  return (
    <>
      {!hideSR && (
        <div
          style={{
            width: `100% `,
            minWidth: '74px',
            height: '25px',
            textAlign: 'center',
            backgroundColor: colors.colorErrorMain,
          }}
        >
          {t('labels.sr')}
        </div>
      )}
      {!hideNA && (
        <div
          style={{
            width: `100% `,
            minWidth: '74px',
            height: '25px',
            textAlign: 'center',
            backgroundColor: '#808080',
            color: 'white',
          }}
        >
          {t('labels.na')}
        </div>
      )}
      <div
        style={{
          width: `100% `,
          minWidth: '74px',
          height: '25px',
          textAlign: 'center',
          backgroundColor: '#000',
          justifyContent: 'space-between',
          display: 'flex',
          alignItems: 'center',
          color: 'white',
        }}
      >
        {false && (
          <Button style={{ height: '25px', width: '24px' }} icon="far fa-clock" onClick={onEventClick}></Button>
        )}
        <Button
          style={{ height: '25px', width: '24px' }}
          icon="fas fa-plane-departure"
          onClick={showDeparture}
        ></Button>

        <span style={{ alignItems: 'center', justifyContent: 'center', display: 'flex', width: 'calc(100% - 25px)' }}>
          {' '}
          {t('labels.available')}
        </span>
      </div>
    </>
  );
};

export default ResourceFooter;
