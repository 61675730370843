import { useEffect, useState } from 'react';

import { useFilters } from '@app/modules/filter/filterContext';
import { useUser } from '@app/modules/user/UserContext';
import { storage } from '@app/store/localstorage/localStorage';
import { RootState } from '@app/store/store';
import colors from '@app/styles/colors';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect, MultiSelectPanelHeaderTemplateEvent } from 'primereact/multiselect';
import { isMobile, isMobileOnly, useMobileOrientation } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import CalendarSelector from '../calendar/CalendarSelector';

const PerformanceFilters: React.FC = () => {
  const { orientation } = useMobileOrientation();
  const [isLocaleSet, setIslocaleSet] = useState(false);
  const filtersCTX = useFilters();
  const performanceFilters = filtersCTX.filters.performanceFilters;
  const userCTX = useUser();
  const isTech = userCTX.authenticationInfos.user?.IsAdmin;
  const selectedHotel = userCTX.authenticationInfos.selectedHotel;
  const authData = userCTX.authenticationInfos;
  const hotelList = userCTX.authenticationInfos.listHotel;
  const darkMode = useSelector((state: RootState) => state.ui.darkMode);
  const [yearsList, setYearsList] = useState<number[]>([]);
  const [t] = useTranslation();
  const DataGroupedBy = [
    { name: t('labels.day'), code: 0 },
    { name: t('labels.week'), code: 1 },
    { name: t('labels.month'), code: 2 },
    { name: t('labels.none'), code: 3 },
  ];

  useEffect(() => {
    if (hotelList?.length === 1 && performanceFilters !== null) {
      filtersCTX.setFilters((prevState) => ({
        ...prevState,
        performanceFilters: {
          ...prevState.performanceFilters,
          ListHotels: hotelList,
        },
      }));
    } else {
      if (selectedHotel) {
        filtersCTX.setFilters((prevState) => ({
          ...prevState,
          performanceFilters: {
            ...prevState.performanceFilters,
            ListHotels: [selectedHotel as HotelRight],
          },
        }));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hotelList]);
  useEffect(() => {
    if (performanceFilters.DateStart) {
      const year = [];
      for (let i = 1; i < 6; i++) {
        year.push(moment(performanceFilters.DateStart).subtract(i, 'year').year());
      }
      setYearsList(year);
    }
  }, [performanceFilters.DateStart]);

  const customHeader = (options: MultiSelectPanelHeaderTemplateEvent) => {
    const onHeaderCheckboxChange = (e: any) => {
      // e.checked donne l'état actuel de la case à cocher
      // Utilisez cette valeur pour gérer le changement
      options.onChange(e.checked);
    };
    return (
      <div className="p-multiselect-header" style={{ alignItems: 'center', marginTop: '10px' }}>
        <div className="p-checkbox p-component">
          <input
            style={{
              border: '2px solid #ced4da',
              background: '#ffffff',
              width: '20px',
              height: '20px',
              color: '#495057',
              borderRadius: '4px',

              transition: 'background-color 0.2s, color 0.2s, border-color 0.2s, box-shadow 0.2s',
            }}
            type="checkbox"
            role="checkbox"
            checked={options.checked}
            onChange={(e) => onHeaderCheckboxChange({ originalEvent: e, checked: e.target.checked })}
          />
        </div>
        <span style={{ marginLeft: '10px' }}>Tous</span>
      </div>
    );
  };

  useEffect(() => {
    setIslocaleSet(false);
    if (authData.language) {
      setIslocaleSet(true);
    }
  }, [authData.language]);

  return (
    <div
      style={{
        display: !isMobile ? 'flex' : '',
        flexDirection: isMobileOnly ? (orientation === 'portrait' ? 'column' : 'row') : 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {isLocaleSet && performanceFilters && isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              padding: '5px',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <CalendarSelector
              numberOfMonthtoDisplay={2}
              data={{ DateStart: performanceFilters.DateStart, DateEnd: performanceFilters.DateEnd }}
              updateParentFilter={(start: number | string | Date, end: number | string | Date) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  performanceFilters: {
                    ...prevState.performanceFilters,
                    DateStart: start,
                    DateEnd: end,
                    hasBeenModified: true,
                  },
                }));
              }}
              shouldClose={performanceFilters.needReload}
              width="160px"
            ></CalendarSelector>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '5px',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {!isTech && (
              <MultiSelect
                disabled={hotelList?.length === 1}
                value={performanceFilters.ListHotels}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    performanceFilters: {
                      ...prevState.performanceFilters,
                      ListHotels: e.value,
                      hasBeenModified: true,
                    },
                  }));
                }}
                panelHeaderTemplate={customHeader}
                options={
                  hotelList?.sort((a, b) => {
                    if (a.Name < b.Name) {
                      return -1;
                    }
                    if (a.Name > b.Name) {
                      return 1;
                    }
                    return 0;
                  }) ?? []
                }
                optionLabel="Name"
                placeholder={t('label.placeholder.choice') ?? ''}
                style={{ width: '190px' }}
              />
            )}
            {isTech && (
              <Dropdown
                value={selectedHotel}
                onChange={async (e) => {
                  userCTX.setUser((prevState) => ({
                    ...prevState,
                    selectedHotel: e.value ?? '',
                  }));
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    performanceFilters: {
                      ...prevState.performanceFilters,
                      ListHotels: e.value,
                      hasBeenModified: true,
                    },
                  }));
                  storage.setParam('selectedHotel', JSON.stringify(e.value));
                }}
                options={hotelList as HotelData[]}
                optionLabel="Name"
                filter
                placeholder={t('label.placeholder.choice') ?? ''}
                className="w-full md:w-14rem"
                style={{ whiteSpace: 'nowrap', maxWidth: '160px' }}
              />
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              padding: '5px',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Dropdown
              value={DataGroupedBy.find((e) => e.code === performanceFilters.GroupBy)}
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  performanceFilters: {
                    ...prevState.performanceFilters,
                    GroupBy: e.value.code,
                    hasBeenModified: true,
                  },
                }));
              }}
              options={DataGroupedBy}
              optionLabel="name"
              placeholder={t('labels.groupby') ?? ''}
              style={{ width: '190px' }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Dropdown
              id="CompareTo"
              value={performanceFilters.YearToCompare}
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  performanceFilters: {
                    ...prevState.performanceFilters,
                    YearToCompare: e.value,
                    hasBeenModified: true,
                  },
                }));
              }}
              options={yearsList}
              placeholder={t('labels.comparaision') ?? ''}
              style={{ marginRight: '5px', marginLeft: '5px', width: '190px' }}
            />

            <Button
              id="PerformanceButton"
              disabled={
                performanceFilters.YearToCompare === 0 ||
                performanceFilters.GroupBy === -1 ||
                performanceFilters.ListHotels.length <= 0
              }
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                borderRadius: '4px',
                backgroundColor: filtersCTX.filters.performanceFilters.hasBeenModified
                  ? colors.colorSuccessMain
                  : darkMode
                    ? 'var(--color-primary-light)'
                    : 'var(--color-primary-main)',

                textAlign: 'center',
                justifyContent: 'center',
              }}
              icon="pi pi-refresh"
              onClick={() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  performanceFilters: {
                    ...prevState.performanceFilters,
                    needReload: true,
                    hasBeenModified: false,
                  },
                }));
              }}
            ></Button>
          </div>
        </div>
      )}
      {isLocaleSet && performanceFilters && !isMobile && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',

            flexWrap: 'wrap',
            alignContent: 'center',
            alignItems: 'center',
            flexDirection: 'row',
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <CalendarSelector
              numberOfMonthtoDisplay={2}
              data={{ DateStart: performanceFilters.DateStart, DateEnd: performanceFilters.DateEnd }}
              updateParentFilter={(start: number | string | Date, end: number | string | Date) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  performanceFilters: {
                    ...prevState.performanceFilters,
                    DateStart: start,
                    DateEnd: end,
                    hasBeenModified: true,
                  },
                }));
              }}
              shouldClose={performanceFilters.needReload}
              width="160px"
            ></CalendarSelector>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            {!isTech && (
              <MultiSelect
                disabled={hotelList?.length === 1}
                value={performanceFilters.ListHotels}
                onChange={(e) => {
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    performanceFilters: {
                      ...prevState.performanceFilters,
                      ListHotels: e.value,
                      hasBeenModified: true,
                    },
                  }));
                }}
                panelHeaderTemplate={customHeader}
                options={
                  hotelList?.sort((a, b) => {
                    if (a.Name < b.Name) {
                      return -1;
                    }
                    if (a.Name > b.Name) {
                      return 1;
                    }
                    return 0;
                  }) ?? []
                }
                optionLabel="Name"
                placeholder={t('label.placeholder.choice') ?? ''}
                style={{ marginRight: '10px', marginLeft: '10px', width: '190px' }}
              />
            )}
            {isTech && (
              <Dropdown
                value={selectedHotel}
                onChange={async (e) => {
                  userCTX.setUser((prevState) => ({
                    ...prevState,
                    selectedHotel: e.value ?? '',
                  }));
                  filtersCTX.setFilters((prevState) => ({
                    ...prevState,
                    performanceFilters: {
                      ...prevState.performanceFilters,
                      ListHotels: e.value,
                      hasBeenModified: true,
                    },
                  }));
                  storage.setParam('selectedHotel', JSON.stringify(e.value));
                }}
                options={hotelList as HotelData[]}
                optionLabel="Name"
                filter
                placeholder={t('label.placeholder.choice') ?? ''}
                className="w-full md:w-14rem"
                style={{ whiteSpace: 'nowrap', maxWidth: '160px', marginRight: '10px', marginLeft: '10px' }}
              />
            )}
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Dropdown
              value={DataGroupedBy.find((e) => e.code === performanceFilters.GroupBy)}
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  performanceFilters: {
                    ...prevState.performanceFilters,
                    GroupBy: e.value.code,
                    hasBeenModified: true,
                  },
                }));
              }}
              options={DataGroupedBy}
              optionLabel="name"
              placeholder={t('labels.groupby') ?? ''}
              style={{ marginRight: '10px', marginLeft: '10px', width: '190px' }}
            />
          </div>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',

              flexWrap: 'wrap',
              alignContent: 'center',
              alignItems: 'center',
              flexDirection: 'row',
            }}
          >
            <Dropdown
              id="CompareTo"
              value={performanceFilters.YearToCompare}
              onChange={(e) => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  performanceFilters: {
                    ...prevState.performanceFilters,
                    YearToCompare: e.value,
                    hasBeenModified: true,
                  },
                }));
              }}
              options={yearsList}
              placeholder={t('labels.comparaision') ?? ''}
              style={{ marginRight: '10px', marginLeft: '10px', width: '190px' }}
            />

            <Button
              id="PerformanceButton"
              disabled={
                performanceFilters.YearToCompare === 0 ||
                performanceFilters.GroupBy === -1 ||
                performanceFilters.ListHotels.length <= 0
              }
              style={{
                marginRight: '10px',
                marginLeft: '10px',
                borderRadius: '4px',
                backgroundColor: filtersCTX.filters.performanceFilters.hasBeenModified
                  ? colors.colorSuccessMain
                  : darkMode
                    ? 'var(--color-primary-light)'
                    : 'var(--color-primary-main)',

                textAlign: 'center',
                justifyContent: 'center',
              }}
              icon="pi pi-refresh"
              onClick={() => {
                filtersCTX.setFilters((prevState) => ({
                  ...prevState,
                  performanceFilters: {
                    ...prevState.performanceFilters,
                    needReload: true,
                    hasBeenModified: false,
                  },
                }));
              }}
            ></Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PerformanceFilters;
